import { useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Api } from "@donatqr/common-iri";

import { schema } from "../forms/createCampaignForm";

function setCampaignFormValues(campaign, setFormValue) {
    const { attributes } = campaign;
    setFormValue("name", attributes.name);
    setFormValue("description", attributes.description);
    setFormValue("dateFrom", attributes.start_date);
    setFormValue("dateTo", attributes.end_date);
    if (!attributes.end_date) {
        setFormValue("indefiniteDuration", true);
    }
    setFormValue("images", attributes.images);
}

export default function useUpsertCapmaign({ campaignId, organizationId, onSuccess }) {
    const { register, handleSubmit, formState, control, watch, setValue } = useForm({
        resolver: yupResolver(schema),
    });

    let [campaign, isCampaignLoading, isCampaignFetched] = [null, null, null];

    if (campaignId) {
        const query = useQuery(["campaigns", campaignId], () => Api.Campaign.getCampaign({ campaignId }));
        campaign = query.data;
        isCampaignLoading = query.isLoading;
        isCampaignFetched = query.isFetched;
    }

    useEffect(() => {
        if (campaign) {
            setCampaignFormValues(campaign, setValue);
        }
    }, [campaign]);

    const mutationFn = async (campaignData) => {
        if (campaignId) {
            await Api.Campaign.updateCampaign({ ...campaignData, campaignId });
        } else {
            await Api.Campaign.createCampaign({ ...campaignData, organizationId });
        }
    };

    const mutation = useMutation({
        mutationKey: ["campaigns"],
        mutationFn,
        onSuccess,
    });

    return {
        formState,
        isCampaignLoading,
        register,
        handleSubmit,
        control,
        watch,
        mutation,
        isCampaignFetched,
        campaign,
    };
}
