import React, { createContext, useMemo } from "react";
import { useThunkReducer } from "react-hook-thunk-reducer";
import PropTypes from "prop-types";

import { authReducer } from "./reducer";

const AuthContext = createContext({});

const initialState = {
    error: null,
    accessToken: null,
    succeeded: null,
    loading: false,
};

function AuthContextProvider({ children }) {
    const [authState, authDispatch] = useThunkReducer(authReducer, initialState);

    const globalContextValue = useMemo(
        () => ({
            authState,
            authDispatch,
        }),
        [authState, authDispatch]
    );

    return <AuthContext.Provider value={globalContextValue}>{children}</AuthContext.Provider>;
}

AuthContextProvider.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

AuthContextProvider.defaultProps = {
    children: [],
};

export { AuthContext, AuthContextProvider };
