import * as yup from "yup";

const MAX_FILE_SIZE_MB = 1;

function checkFileSize(files) {
    return files
        .map((file) => {
            if (!file.size) {
                return true;
            }
            return file.size < MAX_FILE_SIZE_MB * 10 ** 6;
        })
        .every((val) => val);
}

export const schema = yup.object({
    name: yup.string().required("Potreban je naziv kampanje"),
    description: yup
        .string()
        .required("Potreban je opis kampanje")
        .max(500, "Opis mora biti duljine najviše 500 znakova"),
    dateFrom: yup.date("Potreban je datum početka kampanje").nullable().required("Potreban je datum početka kampanje"),
    dateTo: yup
        .date()
        .nullable()
        .when(["indefiniteDuration"], (indefiniteDuration, dateToSchema) =>
            indefiniteDuration ? dateToSchema : dateToSchema.required("Potreban je datum kraja kampanje")
        ),
    indefiniteDuration: yup.bool().default(false),
    images: yup
        .array()
        .min(1, "Potrebna je najmanje jedna slika")
        .test("is-image-big", "Veličina slike treba biti do 1 MB", checkFileSize)
        .default([]),
});
