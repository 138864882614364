import axios from "axios";

const { API_URL, API_TOKEN } = window.__RUNTIME_CONFIG__;

const axiosInstance = axios.create({
    baseURL: `${API_URL}/api`,
    headers: {
        Authorization: `Bearer ${API_TOKEN}`,
    },
});

const IGNORE_AUTH_REQUESTS = [
    { method: "post", url: "/auth/local" },
];

function shouldIgnoreAuth(request) {
    return IGNORE_AUTH_REQUESTS.filter((r) => r.method === request.method && r.url === request.url).length > 0;
}

function isLoginError(error) {
    return error.response.config.url === "/auth/local";
}

axiosInstance.interceptors.request.use((config) => {
    const request = { method: config.method, url: config.url };

    if (shouldIgnoreAuth(request)) {
        return config;
    }

    if (window.localStorage.accessToken) {
        config.headers.Authorization = `Bearer ${window.localStorage.accessToken}`;
    }
    return config;
});

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        if ((error.response.status === 401 || error.response.status === 403) && !isLoginError(error)) {
            delete window.localStorage.accessToken;
            window.location = "/login";
        }
        return Promise.reject(error.response);
    }
);

export default axiosInstance;
