export function extractImagesData(entity) {
    const images = entity?.attributes?.images;

    if (!images) {
        entity.attributes.images = [];
        return  entity;
    }

    const mappedImages = images.data.map(img => ({
        id: img.id,
        url: img.attributes.url,
        alternativeText: img.attributes.alternativeText
    }));

    entity.attributes.images = mappedImages;
    return entity;
}


export function mapCampaignDates(campaign) {
    const { attributes } = campaign;
    attributes.start_date = new Date(attributes.start_date);
    attributes.end_date = attributes.end_date ? new Date(attributes.end_date) : null;
    return campaign;
}


export function mapDateToHours(date) {
    const hours = `0${date.getHours()}`.slice(-2);
    const minutes = `0${date.getMinutes()}`.slice(-2);
    return `${hours}:${minutes}`;
}

export function unwrapNeed(need) {
    const { base } = need.attributes;
    const newBase = { ...base };
    delete need.attributes.base;
    need.attributes = {...need.attributes, ...newBase};
    return need;
}
