import React, { Fragment } from "react";
import { Container, Row, Col, Badge } from "react-bootstrap";
import { ClockFill } from "react-bootstrap-icons";
import PropTypes from "prop-types";

import { CampaignTypeNav, ImageGallery, InfoWithIcon } from "../components";
import { dateToDisplayString } from "../utils";

export default function Campaign({ name, description, imageUrls, dateFrom, dateTo, children }) {
    return (
        <>
            <Container className="bg-white mb-2" fluid>
                <Row className="justify-content-center">
                    <Col lg={8} md={10} xs={11}>
                        <h1 className="pt-5 font-weight-bold d-inline-block">{name}</h1>
                        {
                            (dateTo && dateTo < new Date()) &&
                            <Badge variant="danger" className="d-inline-block ml-3" style={{ fontSize: "1em" }}>Zatvoreno za donacije</Badge>
                        }
                        <div className="text-secondary lead mb-4">
                            <InfoWithIcon
                                icon={<ClockFill />}
                                heading="Vrijeme trajanja kampanje"
                                value={`${dateToDisplayString(dateFrom)} - ${dateTo ? dateToDisplayString(dateTo) : "..."}`}
                            />
                        </div>
                        <pre className="lead">{description}</pre>
                    </Col>
                </Row>
            </Container>
            <ImageGallery height="400px" imageUrls={imageUrls} altTemplate="test" />
            <Container className="mb-2">
                <Row className="justify-content-center mt-4 mb-5">
                    <Col xl={7} lg={8} md={11} xs={12}>
                        <CampaignTypeNav.Tabs>{children}</CampaignTypeNav.Tabs>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

Campaign.propTypes = {
    name: PropTypes.string,
    description: PropTypes.string,
    imageUrls: PropTypes.arrayOf(PropTypes.string),
    dateFrom: PropTypes.instanceOf(Date),
    dateTo: PropTypes.instanceOf(Date),
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

Campaign.defaultProps = {
    name: "Učitavam kampanju...",
    imageUrls: ["/loading.gif"],
    description: "...",
    dateFrom: new Date(2022, 8, 1),
};
