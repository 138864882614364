import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import "bootstrap/dist/css/bootstrap.min.css";
import { Components, Views } from "@donatqr/common-iri";

import { AuthContextProvider } from "./context/AuthContext";
import Ping from "./pages/Ping";
import LoginPage from "./pages/Login";
import OrganizationPage from "./pages/Organization";
import CampaignPage from "./pages/Campaign";
import CreateCampaignPage from "./pages/CreateCampaign";
import CreateNeedPage from "./pages/CreateNeed";
import DonationsPage from "./pages/Donations";
import AllProductDonations from "./pages/AllProductDonations";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
            refetchOnWindowFocus: false,
            staleTime: 60 * 1000, // one minute,
        },
    },
});

export default function App() {
    return (
        <Router>
            <AuthContextProvider>
                <QueryClientProvider client={queryClient}>
                    <Routes>
                        <Route element={<Components.NavLayout isAdmin />}>
                            <Route path="/" element={<OrganizationPage />} />
                            <Route path="/:organizationId/campaign/create" element={<CreateCampaignPage />} />
                            <Route path="/:organizationId/campaign/:campaignId/edit" element={<CreateCampaignPage />} />
                            <Route path="/:organizationId/campaign/:campaignId" element={<CampaignPage />} />
                            <Route
                                path="/:organizationId/campaign/:campaignId/need/create"
                                element={<CreateNeedPage />}
                            />
                            <Route path="/:organizationId/presents" element={<AllProductDonations />} />
                            <Route path="/:organizationId/campaign/:campaignId/donations" element={<DonationsPage />} />
                        </Route>
                        <Route path="/login" element={<LoginPage />} />
                        <Route path="*" element={<Views.Empty />} />
                        <Route path="/ping" element={<Ping />} />
                    </Routes>
                </QueryClientProvider>
            </AuthContextProvider>
        </Router>
    );
}
