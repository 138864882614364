import { useQuery, useQueryClient, useMutation } from "react-query";
import { Api } from "@donatqr/common-iri";
import { useState, useEffect } from "react";

function expandDonation(donation, donationType) {
    const need = donation.attributes.need.data;
    if (donationType === "monetary" && need.attributes.minimal_donation === 1) {
        return [donation];
    }

    const donationsCount = donation.attributes.quantity;
    return [...Array(donationsCount).keys()].map(() => ({ ...donation }));
}

export default function useDonations({ donationCollectionId, enabled, donationType }) {
    const [donationsState, setDonationsState] = useState([]);
    const [donationCollectionStatusState, setDonationCollectionStatusState] = useState({
        status: null,
        changed: false,
    });
    const [updatedDonations, setUpdatedDonations] = useState({});
    const queryClient = useQueryClient();

    const { data: donationCollection } = useQuery(
        ["donation-collection", donationType, donationCollectionId],
        () => Api.Donation.getDonationCollection({ donationCollectionId, donationType }),
        { enabled }
    );

    const { data: donationsResp, isSuccess: donationsFetched } = useQuery(
        ["donations", donationType, donationCollectionId],
        () =>
            Api.Donation.getDonations({
                collectionId: donationCollectionId,
                donationType,
            }),
        { enabled }
    );

    const donations = donationsResp || [];

    useEffect(() => {
        if (enabled && donationsFetched) {
            setDonationsState(JSON.parse(JSON.stringify(donations)));
        }
    }, [donations, enabled]);

    useEffect(() => {
        if (!enabled) {
            setDonationCollectionStatusState({ status: null, changed: false });
        }
    }, [enabled]);

    const changeDonationCollectionStatus = (event) => {
        setDonationCollectionStatusState({ status: event.target.value, changed: true });
    };

    const reduceDonation = (donationId) => {
        const newDonations = [...donationsState];
        const reducedDonation = newDonations.find((donation) => donation.id === donationId);

        const need = reducedDonation.attributes.need.data;
        if (donationType === "monetary" && need.attributes.minimal_donation === 1) {
            reducedDonation.attributes.quantity = 0;
        } else {
            reducedDonation.attributes.quantity -= 1;
        }

        setDonationsState(newDonations);
        setUpdatedDonations({
            ...updatedDonations,
            [reducedDonation.id]: reducedDonation,
        });
    };

    let expandedDonations = [];
    donationsState
        .filter((donation) => donation.attributes.quantity || donationType === "volunteering")
        .forEach((donation) => {
            expandedDonations = expandedDonations.concat(expandDonation(donation, donationType));
        });

    const mutation = useMutation(
        ["donations", donationType, donationCollectionId],
        () => {
            const donationUpdates = Object.values(updatedDonations).map((donation) =>
                Api.Donation.updateDonationQuantity({
                    donationId: donation.id,
                    quantity: donation.attributes.quantity,
                    donationType,
                })
            );
            if (!donationCollectionStatusState.changed) {
                return Promise.all(donationUpdates);
            }
            const updateDonationCollectionStatus = Api.Donation.updateDonationCollectionStatus({
                status: donationCollectionStatusState.status,
                donationCollectionId,
                donationType,
            });
            return Promise.all([...donationUpdates, updateDonationCollectionStatus]);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["donations", donationType, donationCollectionId]);
                queryClient.invalidateQueries(["donation-collections", donationType]);
            },
        }
    );

    return {
        donationCollection,
        donations: expandedDonations,
        reduceDonation,
        mutation,
        changeDonationCollectionStatus,
        donationCollectionStatus: donationCollectionStatusState.status || donationCollection?.attributes?.status,
    };
}
