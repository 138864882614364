import axios from "./axios";
import { mapCampaignDates, extractImagesData } from "./mappers";
import { uploadFile } from "./files";

export async function getCampaignsByUser({ userId }) {
    const { data } = await axios.get("/campaigns", {
        params: {
            populate: "images",
            "filters[organization][user_id]": userId,
        },
    });

    const campaigns = data.data
        .map((campaign) => mapCampaignDates(campaign))
        .map((campaign) => extractImagesData(campaign));
    return campaigns;
}

export async function getCampaign({ campaignId }) {
    const { data } = await axios.get(`/campaigns/${campaignId}`, {
        params: {
            populate: "images",
        },
    });

    const campaign = data.data;
    return extractImagesData(mapCampaignDates(campaign));
}

export async function getCampaignsByOrganizationId({ organizationId, pageNumber }) {
    const { data } = await axios.get(`/campaigns`, {
        params: {
            "populate[images]": 1,
            "filters[organization][id]": organizationId,
            "populate[product_needs][populate][base]": 1,
            "populate[product_needs][populate][donations][filters][donation_collection][status][$in][0]": "pending",
            "populate[product_needs][populate][donations][filters][donation_collection][status][$in][1]": "delivered",
            "pagination[page]": pageNumber,
            "pagination[pageSize]": 15,
            "sort": "id",
        },
    });

    const campaigns = data.data.map((campaign) => extractImagesData(mapCampaignDates(campaign)));
    const paginationInfo = data.meta.pagination;

    return {campaigns, paginationInfo};
}

export async function getSpecialPromotionCampaigns({ specialPromotion }) {
    const { data } = await axios.get(`/campaigns`, {
        params: {
            "populate[images]": 1,
            "populate[organization]": 1,
            "filters[special_promotion]": specialPromotion,
            "populate[product_needs][populate][base]": 1,
            "populate[product_needs][populate][donations][filters][donation_collection][status][$in][0]": "pending",
            "populate[product_needs][populate][donations][filters][donation_collection][status][$in][1]": "delivered",
            "pagination[pageSize]": 100,
            "sort": "id",
        },
    });

    const campaigns = data.data.map((campaign) => extractImagesData(mapCampaignDates(campaign)));

    return campaigns;
}

export async function createCampaign({
    name,
    description,
    dateFrom,
    dateTo,
    indefiniteDuration,
    images,
    organizationId,
}) {
    const imageUploads = await Promise.all(images.map((img) => uploadFile(img)));

    if (indefiniteDuration) {
        dateTo = null;
    }

    const { data } = await axios.post("/campaigns", {
        data: {
            name: name,
            description: description,
            images: imageUploads.map((imgData) => imgData.id),
            start_date: dateFrom,
            end_date: dateTo,
            organization: Number(organizationId),
        },
    });
    return data;
}

export async function updateCampaign({ name, description, dateFrom, dateTo, indefiniteDuration, images, campaignId }) {
    const upsertImages = images.map(async (img) => {
        if (img.id) {
            return img.id;
        } else {
            const imgUploaded = await uploadFile(img);
            return imgUploaded.id;
        }
    });

    const imageIds = await Promise.all(upsertImages);

    if (indefiniteDuration) {
        dateTo = null;
    }

    const { data } = await axios.put(`/campaigns/${campaignId}`, {
        data: {
            name: name,
            description: description,
            images: imageIds,
            start_date: dateFrom,
            end_date: dateTo,
        },
    });
    return data;
}

export async function removeCampaign({ campaignId }) {
    const { data } = await axios.delete(`/campaigns/${campaignId}`);
    return data.data;
}
