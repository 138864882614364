import * as yup from "yup";

export const schema = yup.object({
    name: yup.string().required("Potreban je naziv donacije"),
    description: yup.string().notRequired().max(500, "Opis mora biti duljine najviše 500 znakova"),
    address: yup.string().required("Potrebna je adresa dostave"),
    goal: yup
        .number()
        .positive("Količina treba biti pozitivan broj")
        .typeError("Količina mora biti cijeli broj")
        .required("Potrebna je količina"),
    unit: yup.string().required("Potrebna je mjerna jedinica"),
});

export const onSubmit = (data) => {
    console.log({ data });
};
