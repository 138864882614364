import React from "react";
import { Badge } from "react-bootstrap";

export const MONETARY_DONATION_STATUSES = {
    pending: "pending",
    accepted: "accepted",
    declined: "declined",
};

export const MONETARY_DONATION_LABEL_BY_STATUS = {
    pending: "Nije potvrđeno",
    accepted: "Prihvaćeno",
    declined: "Odbijeno",
};

export const MONETARY_DONATION_STATUS_BADGES = {
    pending: (
        <Badge style={{ fontSize: ".9em" }} variant="secondary">
            Nije potvrđeno
        </Badge>
    ),
    accepted: (
        <Badge style={{ fontSize: ".9em" }} variant="success">
            Prihvaćeno
        </Badge>
    ),
    declined: (
        <Badge style={{ fontSize: ".9em" }} variant="danger">
            Odbijeno
        </Badge>
    ),
};

export const MONETARY_DONOR_TYPE = {
    company: "Pravna osoba",
    person: "Fizička osoba",
};

export const PRODUCT_DONATION_STATUSES = {
    pending: "pending",
    delivered: "delivered",
    declined: "declined",
};

export const PRODUCT_DONATION_STATUS_BADGES = {
    pending: (
        <Badge style={{ fontSize: ".9em" }} variant="secondary">
            Nije potvrđeno
        </Badge>
    ),
    delivered: (
        <Badge style={{ fontSize: ".9em" }} variant="success">
            Dostavljeno
        </Badge>
    ),
    declined: (
        <Badge style={{ fontSize: ".9em" }} variant="danger">
            Odbijeno
        </Badge>
    ),
};

export const PRODUCT_DONATION_LABEL_BY_STATUS = {
    pending: "Nije potvrđeno",
    delivered: "Dostavljeno",
    declined: "Odbijeno",
};

export const VOLUNTEERING_DONATION_LABEL_BY_STATUS = {
    pending: "Nije potvrđeno",
    participated: "Volonter prisustovao",
    accepted: "Dolazak potvrđen",
    did_not_participate: "Volonter nije prisustovao",
};

export const VOLUNTEERING_DONATION_STATUSES = {
    pending: "pending",
    participated: "participated",
    accepted: "accepted",
    did_not_participate: "did_not_participate",
};

export const VOLUNTEERING_DONATION_STATUS_BADGES = {
    pending: (
        <Badge style={{ fontSize: ".9em" }} variant="secondary">
            Nije potvrđeno
        </Badge>
    ),
    participated: (
        <Badge style={{ fontSize: ".9em" }} variant="success">
            Volonter prisustovao
        </Badge>
    ),
    accepted: (
        <Badge style={{ fontSize: ".9em" }} variant="warning">
            Dolazak potvrđen
        </Badge>
    ),
    did_not_participate: (
        <Badge style={{ fontSize: ".9em" }} variant="danger">
            Volonter nije prisustovao
        </Badge>
    ),
};
