import React from "react";
import { useNavigate } from "react-router-dom";
import { Container, Col, Row, Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "react-query";
import { Api } from "@donatqr/common-iri";

import { schema } from "../forms/loginForm";

const LOGIN_FORM_URL =
    "https://docs.google.com/forms/d/e/1FAIpQLSesdHZeXA8DkPr_1695pQ_moN3YYnuRHcQ3pFNAKFdq4RvpbA/viewform?usp=sf_link";

export default function LoginPage() {
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const mutation = useMutation({
        mutationFn: ({ email, password }) => Api.User.login({ identifier: email, password }),
        onSuccess: ({ jwt }) => {
            window.localStorage.accessToken = jwt;
        },
    });

    if (mutation.isSuccess) {
        navigate("/");
    }

    const onSubmit = (e) => {
        window.localStorage.accessToken = null;
        handleSubmit(mutation.mutate)(e);
    };

    return (
        <Container className="container-sm p-5">
            <div className="d-flex justify-content-center mb-3">
                <img src="/dvz-logo.png" height={100} alt="logo" />
            </div>
            <Row className="justify-content-center">
                <Col className="bg-white border rounded p-5" lg={5} md={7} xs={14}>
                    <h1 className="mb-4 text-center h4 text-primary">Prijava</h1>
                    <form onSubmit={onSubmit} noValidate>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email adresa</Form.Label>
                            <Form.Control {...register("email")} type="email" placeholder="E-mail udruge" />
                            <small className="text-danger">{errors?.email?.message}</small>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Lozinka</Form.Label>
                            <Form.Control {...register("password")} type="password" placeholder="Lozinka" />
                            <small className="text-danger">{errors?.password?.message}</small>
                        </Form.Group>

                        <Button variant="primary" className="mb-3 btn-block" type="submit">
                            Prijava
                        </Button>
                        {mutation.isError && <small className="text-danger">Netočni podatci za prijavu!</small>}
                    </form>
                </Col>
                <Col className="mt-4" lg={12}>
                    <p className="text-center text-secondary">
                        Nemaš korisnički račun?
                        <a className="ml-2" href={LOGIN_FORM_URL} target="_blank" rel="noreferrer">
                            Registriraj se!
                        </a>
                    </p>
                </Col>
            </Row>
        </Container>
    );
}
