import React, { Fragment } from "react";
import { Container } from "react-bootstrap";
import { ClockFill } from "react-bootstrap-icons";
import { Utils, Components, Api } from "@donatqr/common-iri";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

import { MonetaryDonationTable } from "../components/MonetaryDonationTable";
import { ProductDonationTable } from "../components/ProductDonationTable";
import { VolunteeringDonationTable } from "../components/VolunteeringDonationTable";

export default function Donations() {
    const { dateToDisplayString } = Utils;
    const { campaignId } = useParams();

    const { data: campaign } = useQuery(["campaign", campaignId], () => Api.Campaign.getCampaign({ campaignId }));

    const dateFrom = new Date(campaign?.attributes?.start_date);
    const dateTo = campaign?.attributes?.end_date ? new Date(campaign?.attributes?.end_date) : null;

    return (
        <>
            <Container className="bg-white mb-2 shadow-sm" fluid>
                <Container className="pb-2">
                    <h1 className="pt-5 font-weight-bold">Donacije: {campaign?.attributes?.name}</h1>
                    <div className="text-secondary lead mb-4">
                        <Components.InfoWithIcon
                            icon={<ClockFill />}
                            heading="Vrijeme trajanja kampanje"
                            value={`${dateToDisplayString(dateFrom)} - ${dateTo ? dateToDisplayString(dateTo) : "..."}`}
                        />
                    </div>
                </Container>
            </Container>
            <Container className="mb-2 pt-4">
                <Components.CampaignTypeNav.Tabs>
                    <Components.CampaignTypeNav.MonetaryTab>
                        <MonetaryDonationTable />
                    </Components.CampaignTypeNav.MonetaryTab>
                    <Components.CampaignTypeNav.ProductTab>
                        <ProductDonationTable />
                    </Components.CampaignTypeNav.ProductTab>
                    <Components.CampaignTypeNav.VolunteeringTab>
                        <VolunteeringDonationTable />
                    </Components.CampaignTypeNav.VolunteeringTab>
                </Components.CampaignTypeNav.Tabs>
            </Container>
        </>
    );
}
