import React from "react";
import PropTypes from "prop-types";
import { useController } from "react-hook-form";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import hr from "date-fns/locale/hr"
import "../fixes.css";

registerLocale("hr-HR", hr)

// DatePicker wrapper for usage with react form hook

function UseFormDatePicker({ name, control, ...props }) {
    const { field } = useController({
        name,
        control,
        defaultValue: null,
    });
    const selected = props?.disabled ? null : field.value;

    let dateFormat = null;
    if (props?.showTimeSelect && !props?.showTimeSelectOnly) {
        dateFormat = "yyyy-MM-dd HH:mm";
    } else if (props?.showTimeSelectOnly) {
        props.showTimeSelect = true;
        dateFormat = "HH:mm";
    } else {
        dateFormat = "yyyy-MM-dd";
    }

    return (
        <DatePicker
            dateFormat={dateFormat}
            selected={selected}
            onChange={(date) => {
                field.onChange(date);
            }}
            className="form-control"
            locale="hr-HR"
            {...props}
        />
    );
}

UseFormDatePicker.propTypes = {
    name: PropTypes.string.isRequired,
    control: PropTypes.object.isRequired,
    timeFormat: PropTypes.string,
    disabled: PropTypes.bool,
    showTimeSelect: PropTypes.bool,
    showTimeSelectOnly: PropTypes.bool,
};

UseFormDatePicker.defaultProps = {
    timeFormat: "HH:mm",
    disabled: false,
    showTimeSelect: false,
    showTimeSelectOnly: false,
};

export default UseFormDatePicker;
