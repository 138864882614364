import React from "react";
import PropTypes from "prop-types";
import { Pagination } from "react-bootstrap";


function CustomPagination({ onPageChange, activePage, numberOfPages }) {
    return (
        <Pagination className="float-right">
            <Pagination.Prev
                disabled={activePage === 1}
                onClick={() => onPageChange(activePage-1)}
            />
            {Array.from(Array(numberOfPages).keys()).map((number, pageIndex) => (
                <Pagination.Item
                    key={number}
                    active={(pageIndex+1) === activePage}
                    onClick={() => onPageChange(pageIndex+1)}
                >
                    {pageIndex + 1}
                </Pagination.Item>
            ))}
            <Pagination.Next
                disabled={activePage === numberOfPages}
                onClick={() => onPageChange(activePage+1)}
            />
        </Pagination>
    )
}

CustomPagination.propTypes = {
    onPageChange: PropTypes.func,
    activePage: PropTypes.number,
    numberOfPages: PropTypes.number,
}

export default CustomPagination;
