import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { Table, Form, Card, Row, Col, Dropdown, Button } from "react-bootstrap";
import { PencilFill } from "react-bootstrap-icons";
import { useController, useForm } from "react-hook-form";
import { Api, Utils, Consts } from "@donatqr/common-iri";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";

import MonetaryDonationDetails from "./MonetaryDonationDetails";
import useDonationCollectionBulkEdit from "../hooks/useDonationCollectionBulkEdit";

export function MonetaryDonationTableRow({ control, name, donorName, status, donorType, email, onDetailsClick }) {
    const { field } = useController({
        name,
        control,
        defaultValue: false,
    });

    return (
        <tr className="p-3">
            <td>
                <Form.Check className="d-inline mr-4" type="checkbox" checked={field.value} onChange={field.onChange} />
            </td>
            <td>{donorName}</td>
            <td>{Consts.Donations.MONETARY_DONATION_STATUS_BADGES[status]}</td>{" "}
            <td>{Consts.Donations.MONETARY_DONOR_TYPE[donorType]}</td>
            <td>{email}</td>
            <td>
                <Button variant="light" size="sm" onClick={onDetailsClick}>
                    <PencilFill />
                </Button>
            </td>
        </tr>
    );
}

MonetaryDonationTableRow.propTypes = {
    control: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    donorName: PropTypes.string,
    status: PropTypes.oneOf(Object.keys(Consts.Donations.MONETARY_DONATION_LABEL_BY_STATUS)),
    donorType: PropTypes.oneOf(Object.keys(Consts.Donations.MONETARY_DONOR_TYPE)),
    email: PropTypes.string,
    onDetailsClick: PropTypes.func,
};

MonetaryDonationTableRow.defaultProps = {
    donorName: "John Doe",
    status: "pending",
    donorType: "0",
    email: "josip.bartulovic3@gmail.com",
    onDetailsClick: () => console.log("DETAILS"),
};

export function MonetaryDonationTable() {
    const { register: registerFilter, watch: watchFilters } = useForm();

    const [detailsModalState, setModalState] = useState({ show: false, donationId: null });
    const handleCloseModal = () => {
        setModalState({ ...detailsModalState, show: false });
    };

    const { campaignId } = useParams();

    const { data: donationCollections } = useQuery(["donation-collections", "monetary", campaignId], () =>
        Api.Donation.getDonationCollections({ campaignId, donationType: "monetary" })
    );

    const { controlSelectedCollections, selectedCollectionsCount, mutation } = useDonationCollectionBulkEdit({
        donationType: "monetary",
        campaignId,
    });

    const filteredDonationCollections = Utils.needs.filterDonationCollections(
        donationCollections || [],
        watchFilters()
    );

    return (
        <>
            <Card className="shadow-sm">
                <Card.Body>
                    <Row>
                        <Col xs={12} sm={12} md={6}>
                            <Form.Group>
                                <Form.Label>Ime i prezime</Form.Label>
                                <Form.Control {...registerFilter("name")} type="text" placeholder="John Doe" />
                            </Form.Group>
                        </Col>
                        <Col xs={12} sm={12} md={6}>
                            <Form.Group>
                                <Form.Label>Status</Form.Label>
                                <select {...registerFilter("status")} className="custom-select">
                                    <option value="">Odaberite status...</option>
                                    {Object.entries(Consts.Donations.MONETARY_DONATION_LABEL_BY_STATUS).map(
                                        ([key, status]) => (
                                            <option key={key} value={key}>
                                                {status}
                                            </option>
                                        )
                                    )}
                                </select>
                            </Form.Group>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <Card className="shadow-sm mt-4 mb-4">
                <Card.Body>
                    <Form.Label>Grupno uređivanje</Form.Label>
                    <Dropdown className="d-inline-block ml-3">
                        <Dropdown.Toggle variant="secondary" disabled={!selectedCollectionsCount}>
                            <PencilFill className="mr-1" />{" "}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item
                                onClick={() =>
                                    mutation.mutateAsync(Consts.Donations.MONETARY_DONATION_STATUSES.pending)
                                }
                            >
                                Označi kao nepotvrđeno
                            </Dropdown.Item>
                            <Dropdown.Item
                                onClick={() =>
                                    mutation.mutateAsync(Consts.Donations.MONETARY_DONATION_STATUSES.accepted)
                                }
                            >
                                Prihvati označeno
                            </Dropdown.Item>
                            <Dropdown.Item
                                onClick={() =>
                                    mutation.mutateAsync(Consts.Donations.MONETARY_DONATION_STATUSES.declined)
                                }
                            >
                                Odbij označeno
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Card.Body>
                <Table hover responsive>
                    <thead>
                        <tr>
                            <th>Odaberi</th>
                            <th>Ime i prezime</th>
                            <th>Status</th>
                            <th>Tip donatora</th>
                            <th>email</th>
                            <th>Uredi</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredDonationCollections.map((collection) => (
                            <MonetaryDonationTableRow
                                key={collection.id}
                                control={controlSelectedCollections}
                                name={collection.id.toString()}
                                donorName={collection?.attributes?.full_name}
                                status={collection?.attributes?.status}
                                donorType={Utils.needs.getMonetaryDonorType(collection)}
                                email={collection?.attributes?.email}
                                onDetailsClick={() =>
                                    setModalState({ show: true, donationCollectionId: collection?.id })
                                }
                            />
                        ))}
                    </tbody>
                </Table>
                <MonetaryDonationDetails {...detailsModalState} handleClose={handleCloseModal} />
            </Card>
        </>
    );
}
