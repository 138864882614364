import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import { GeoAltFill } from "react-bootstrap-icons";

import { truncateText } from "../utils";
import InfoWithIcon from "./InfoWithIcon";
import { LOREM_IPSUM } from "../consts";

export default function ProductNeedCard({ name, description, dropOffLocation, collected, goal, unitOfMeasurement, children }) {
    return (
        <Card className="shadow-sm mb-4">
            <Card.Body>
                <Card.Title className="font-weight-bold">{truncateText(name, 40)}</Card.Title>
                <div className="lead text-secondary">
                    <InfoWithIcon icon={<GeoAltFill />} heading="Adresa dostave" value={dropOffLocation} />
                </div>
                { description && <hr /> }
                <p className="mt-2">{description}</p>
                <hr />
                <Row>
                    <Col>
                        <div className="text-secondary">PRIKUPLJENO</div>
                        <div className="text-success font-weight-bold">{collected} {unitOfMeasurement}</div>
                    </Col>
                    <Col xs={1} className="d-none d-sm-block" />
                    <Col>
                        <div className="text-secondary">CILJ</div>
                        <div className="text-warning font-weight-bold">{goal} {unitOfMeasurement}</div>
                    </Col>
                </Row>
                {children}
            </Card.Body>
        </Card>
    );
}

ProductNeedCard.propTypes = {
    name: PropTypes.string,
    description: PropTypes.string,
    dropOffLocation: PropTypes.string,
    collected: PropTypes.number,
    required: PropTypes.number,
    unitOfMeasurement: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

ProductNeedCard.defaultProps = {
    description: LOREM_IPSUM,
    dropOffLocation: "Dobrilina 7, Split",
    name: "Cedevita",
    collected: 22,
    goal: 51,
    unitOfMeasurement: "L"
};
