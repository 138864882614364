import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQueryClient } from "react-query";
import { Container, Form, Row, Col, Card, Button } from "react-bootstrap";
import { Views, Components } from "@donatqr/common-iri";

import MultipleFileInput from "../components/MulitpleFileInput";
import useUpsertCampaign from "../hooks/useUpsertCampaign";

export default function CreateCampaign() {
    const navigate = useNavigate();

    const { campaignId, organizationId } = useParams();

    const queryClient = useQueryClient();

    const {
        formState: { errors },
        isCampaignFetched,
        campaign,
        register,
        handleSubmit,
        control,
        watch,
        mutation,
    } = useUpsertCampaign({
        campaignId,
        organizationId,
        onSuccess: () => {
            queryClient.invalidateQueries(["campaigns"]);
            queryClient.invalidateQueries(["campaigns", campaignId]);
            navigate("/");
        },
    });

    if (!campaign && isCampaignFetched) {
        return <Views.CampaignMissing />;
    }

    return (
        <Container className="mb-5">
            {campaignId ? (
                <h1 className="mt-5 font-weight-bold">Uredi kampanju</h1>
            ) : (
                <h1 className="mt-5 font-weight-bold">Kreiraj novu kampanju</h1>
            )}

            <form className="mt-4" onSubmit={handleSubmit(mutation.mutateAsync)}>
                <Row>
                    {/* INFO */}
                    <Col xs={12} md={9} lg={7}>
                        <Card className="shadow-sm">
                            <Card.Body>
                                <Card.Title className="font-weight-bold">O kampanji</Card.Title>
                                <hr />
                                <Form.Group>
                                    <Form.Label>Naziv kampanje</Form.Label>
                                    <Form.Control {...register("name")} type="text" placeholder="Moja kampanja" />
                                    <small className="text-danger">{errors?.name?.message}</small>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Opis</Form.Label>
                                    <Form.Control
                                        {...register("description")}
                                        as="textarea"
                                        rows="5"
                                        maxLength="500"
                                        placeholder="Cilj kampanje..."
                                    />
                                    <small className="text-danger">{errors?.description?.message}</small>
                                </Form.Group>
                            </Card.Body>
                        </Card>
                    </Col>
                    {/* DURATION */}
                    <Col className="mt-4" xs={12} md={9} lg={7}>
                        <Card className="shadow-sm">
                            <Card.Body>
                                <Card.Title className="font-weight-bold">Trajanje</Card.Title>
                                <hr />
                                <Row>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>Početak</Form.Label>
                                            <Components.DatePicker control={control} name="dateFrom" />
                                            <small className="text-danger">{errors?.dateFrom?.message}</small>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>Kraj</Form.Label>
                                            <Components.DatePicker
                                                control={control}
                                                name="dateTo"
                                                disabled={watch("indefiniteDuration")}
                                            />
                                            <small className="text-danger">{errors?.dateTo?.message}</small>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Form.Check
                                    {...register("indefiniteDuration")}
                                    type="switch"
                                    id="indefinite_duration"
                                    label="Bez definiranog kraja"
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                    {/* IMAGE GALERY */}
                    <Col className="mt-4" xs={12} md={9} lg={7}>
                        <Card className="shadow-sm">
                            <Card.Body>
                                <Card.Title className="font-weight-bold">Galerija</Card.Title>
                                <hr />
                                <small className="text-danger">{errors?.images?.message}</small>
                                <MultipleFileInput control={control} name="images" />
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className="mt-4" xs={12} md={9} lg={7}>
                        <Button variant="primary" className="btn-block" type="submit" disabled={mutation.isLoading}>
                            {campaignId ? "Uredi" : "Kreiraj"}
                        </Button>
                    </Col>
                </Row>
            </form>
        </Container>
    );
}
