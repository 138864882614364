import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Row, Col, Button } from "react-bootstrap";
import { Components, Views, Api, Utils } from "@donatqr/common-iri";
import { useQuery } from "react-query";

import RemoveNeedModal from "../components/RemoveNeedModal";

function renderMonetaryNeeds(needs, handleShow) {
    return needs.map((need) => {
        const collected = Utils.needs.calculateMonetaryNeedCollectedAmount(need);
        return (
            <Components.MonetaryNeedCard
                name={need.attributes.name}
                description={need.attributes.description}
                collected={collected}
                goal={need.attributes.goal}
                price={need.attributes.minimal_donation}
                key={need.id}
            >
                <Row className="mt-3">
                    <Col>
                        <Button
                            onClick={handleShow(need.id, need.attributes.name, "monetary")}
                            variant="outline-danger"
                            className="btn-block"
                            disabled={collected}
                        >
                            Izbriši
                        </Button>
                    </Col>
                </Row>
            </Components.MonetaryNeedCard>
        );
    });
}

function renderProductNeeds(needs, handleShow) {
    return needs.map((need) => (
        <Components.ProductNeedCard
            name={need.attributes.name}
            description={need.attributes.description}
            collected={Utils.needs.calculateNeedCollectedAmount(need)}
            goal={need.attributes.goal}
            dropOffLocation={need.attributes.drop_off_location}
            unitOfMeasurement={need.attributes.unit}
            key={need.id}
        >
            <Row className="mt-3">
                <Col>
                    <Button
                        onClick={handleShow(need.id, need.attributes.name, "product")}
                        variant="outline-danger"
                        className="btn-block"
                    >
                        Izbriši
                    </Button>
                </Col>
            </Row>
        </Components.ProductNeedCard>
    ));
}

function renderVolunteeringNeeds(needs, handleShow) {
    return needs.map((need) => (
        <Components.VolunteeringNeedCard
            name={need.attributes.name}
            description={need.attributes.description}
            location={need.attributes.address}
            date={new Date(need.attributes.date)}
            hourFrom={need.attributes.start_hour}
            hourTo={need.attributes.end_hour}
            goal={need.attributes.goal}
            registered={Utils.needs.calculateNeedCollectedAmount(need)}
            key={need.id}
        >
            <Row className="mt-3">
                <Col>
                    <Button
                        onClick={handleShow(need.id, need.attributes.name, "volunteering")}
                        variant="outline-danger"
                        className="btn-block"
                    >
                        Izbriši
                    </Button>
                </Col>
            </Row>
        </Components.VolunteeringNeedCard>
    ));
}

export default function CampaignPage() {
    const [removeNeedModalProps, setRemoveNeedModalProps] = useState({ show: false });
    const navigate = useNavigate();
    const { campaignId, organizationId } = useParams();

    const handleClose = () => setRemoveNeedModalProps({ show: false });
    const handleShow = (needId, needName, needType) => () =>
        setRemoveNeedModalProps({
            show: true,
            needId,
            needName,
            needType,
        });

    const { data: campaign, isFetched: isCampaignFetched } = useQuery(["campaign", campaignId], () =>
        Api.Campaign.getCampaign({ campaignId })
    );

    const { data: needs } = useQuery(["campaign-needs", campaignId], () => Api.Need.getNeeds({ campaignId }));

    if (!campaign && isCampaignFetched) {
        return <Views.CampaignMissing />;
    }

    return (
        <Views.Campaign
            name={campaign?.attributes?.name}
            description={campaign?.attributes?.description}
            dateFrom={campaign?.attributes?.start_date}
            dateTo={campaign?.attributes?.end_date}
            imageUrls={campaign?.attributes?.images.map((img) => img.url)}
        >
            <Components.CampaignTypeNav.MonetaryTab>
                {renderMonetaryNeeds(needs?.monetary || [], handleShow)}
            </Components.CampaignTypeNav.MonetaryTab>
            <Components.CampaignTypeNav.ProductTab>
                {renderProductNeeds(needs?.product || [], handleShow)}
            </Components.CampaignTypeNav.ProductTab>
            <Components.CampaignTypeNav.VolunteeringTab>
                {renderVolunteeringNeeds(needs?.volunteering || [], handleShow)}
            </Components.CampaignTypeNav.VolunteeringTab>
            <Button
                className="mt-4 btn-block"
                onClick={() => navigate(`/${organizationId}/campaign/${campaignId}/need/create`)}
            >
                Dodaj potrebe
            </Button>
            <RemoveNeedModal handleClose={handleClose} {...removeNeedModalProps} />
        </Views.Campaign>
    );
}
