import axios from "./axios";
import { extractImagesData } from "./mappers";

export async function getOrganizationByUser({ userId }) {
    const { data } = await axios.get("/organizations", {
        params: {
            populate: "images",
            "filters[user_id]": userId,
        },
    });
    const organization = data.data[0] || {};
    return extractImagesData(organization);
}

export async function getOrganizationById({ organizationId }) {
    const { data } = await axios.get(`/organizations/${organizationId}`, {
        params: {
            populate: ["images", "campaigns"],
        },
    });
    const organization = data.data || {};
    return extractImagesData(organization);
}
