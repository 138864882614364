import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import { useQueryClient, useMutation } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { Api, Components } from "@donatqr/common-iri";

import { schema } from "../forms/createVolunteeringNeedForm";

export default function CreateVolunteeringNeedForm() {
    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        watch,
    } = useForm({
        resolver: yupResolver(schema),
    });

    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const { campaignId, organizationId } = useParams();
    const mutation = useMutation(
        ["volunteering-needs", campaignId],
        (need) => Api.Need.createVolunteeringNeed({ ...need, campaignId }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["campaign-needs", campaignId]);
                navigate(`/${organizationId}/campaign/${campaignId}`);
            },
        }
    );

    return (
        <form onSubmit={handleSubmit(mutation.mutateAsync)}>
            <Row>
                <Col xs={12} md={9} lg={7}>
                    <Card className="shadow-sm">
                        <Card.Body>
                            <Form.Group>
                                <Form.Label>Volonterska pozicija</Form.Label>
                                <Form.Control {...register("name")} type="text" placeholder="Fotograf" />
                                <small className="text-danger">{errors?.name?.message}</small>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Adresa događaja</Form.Label>
                                <Form.Control
                                    {...register("address")}
                                    type="text"
                                    placeholder="Ul. Ruđera Boškovića 32"
                                />
                                <small className="text-danger">{errors?.address?.message}</small>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Datum održavanja događaja</Form.Label>
                                <Components.DatePicker control={control} name="eventDate" minDate={new Date()} />
                                <small className="text-danger">{errors?.eventDate?.message}</small>
                            </Form.Group>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Početak događaja</Form.Label>
                                        <Components.DatePicker
                                            control={control}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            name="eventTimeStart"
                                        />
                                        <small className="text-danger">{errors?.eventTimeStart?.message}</small>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Kraj događaja</Form.Label>
                                        <Components.DatePicker
                                            control={control}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            name="eventTimeEnd"
                                            minTime={watch("eventTimeStart") || new Date(2022, 1, 1, 0, 0)}
                                            maxTime={new Date(2022, 1, 1, 23, 30)}
                                        />
                                        <small className="text-danger">{errors?.eventTimeEnd?.message}</small>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Form.Group>
                                <Form.Label>
                                    Opis - <i>opcionalno</i>
                                </Form.Label>
                                <Form.Control
                                    {...register("description")}
                                    as="textarea"
                                    rows="5"
                                    maxLength="500"
                                    placeholder="Dodatne informacije"
                                />
                                <small className="text-danger">{errors?.description?.message}</small>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Broj potrebnih volontera</Form.Label>
                                <Form.Control {...register("goal")} type="number" placeholder="..." />
                                <small className="text-danger">{errors?.goal?.message}</small>
                            </Form.Group>
                        </Card.Body>
                    </Card>
                </Col>
                <Col className="mt-4" xs={12} md={9} lg={7}>
                    <Button variant="primary" className="btn-block" type="submit">
                        Kreiraj
                    </Button>
                </Col>
            </Row>
        </form>
    );
}
