import React from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { Views, Api, Hooks, Components, Consts } from "@donatqr/common-iri";
import { Row, Col, Button } from "react-bootstrap";
import { PlusLg, Gift } from "react-bootstrap-icons";

import CampaignCard from "../components/CampaignCard";

function renderCampaigns(campaigns, organizationId) {
    return campaigns.map((campaign) => (
        <Col key={campaign.id} className="mt-4" sm={6} xs={12}>
            <CampaignCard
                campaignId={campaign.id}
                name={campaign.attributes.name}
                dateFrom={campaign.attributes.start_date}
                dateTo={campaign.attributes.end_date}
                imageUrl={campaign.attributes.images[0]?.url}
                organizationId={organizationId}
            />
        </Col>
    ));
}

export default function OrganizationPage() {
    const navigate = useNavigate();

    const { data: user, isLoading: isLoadingUser } = useQuery({
        queryKey: ["me"],
        queryFn: Api.User.getMe,
    });

    const { data: organization, isFetched: isOrganizationFetched } = useQuery({
        queryKey: ["organization"],
        queryFn: () => Api.Organization.getOrganizationByUser({ userId: user?.id }),
        enabled: !isLoadingUser,
    });

    const { campaigns, page, pageCount, setPage } = Hooks.useCampaignsList(organization?.id);

    if (!organization && isOrganizationFetched) {
        navigate("/login");
    }

    return (
        <Views.Organization
            name={organization?.attributes?.name}
            address={organization?.attributes?.address}
            description={organization?.attributes?.description}
            imageUrls={organization?.attributes?.images.map((img) => img.url)}
        >
            <Row className="justify-content-center">
                <Col lg={6} md={7} xs={11}>
                    <h2 className="font-weight-bold">Kampanje</h2>
                </Col>
                <Col lg={2} md={3} xs={11} className="pb-4">
                    <Button onClick={() => navigate(`/${organization?.id}/campaign/create`)}>
                        <PlusLg /> Nova kampanja
                    </Button>
                    {organization?.id === Consts.ST_NICHOLAS_ORGANIZATION_ID && (
                        <Button onClick={() => navigate(`/${organization?.id}/presents`)} className="mt-2">
                            <Gift /> Pokloni
                        </Button>
                    )}
                </Col>
                <Col lg={8} md={10} xs={11} className="pt-6">
                    <Row>{organization?.id && renderCampaigns(campaigns || [], organization?.id)}</Row>
                </Col>
                <Col lg={8} md={10} xs={11}>
                    <div className="mt-4">
                        <Components.Pagination onPageChange={setPage} activePage={page} numberOfPages={pageCount} />
                    </div>
                </Col>
            </Row>
        </Views.Organization>
    );
}
