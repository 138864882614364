import React from "react";
import { Badge, Card } from "react-bootstrap";
import { ClockFill } from "react-bootstrap-icons";
import PropTypes from "prop-types";

import CampaignTypeBadges from "./CampaignTypeBadges";
import { truncateText, dateToDisplayString } from "../utils";
import InfoWithIcon from "./InfoWithIcon";


function isProductsCampaignFinished(productNeeds) {
    if (!productNeeds.length) { return false; }

    const unfilledProductNeeds = productNeeds
    .filter((need) => {
        const collected = need.attributes.donations.data.reduce((sum, donation) => (sum + donation.attributes.quantity), 0);
        return collected < need.attributes.base.goal;
    });

    return unfilledProductNeeds.length === 0;
}


function CampaignStatusBadge({ dateTo, productNeeds}) {
    if (dateTo && new Date(dateTo) < new Date()) {
        return <Badge variant="danger" className="mb-1">Zatvoreno za donacije</Badge>
    }
    if (isProductsCampaignFinished(productNeeds)) {
        return <Badge variant="warning" className="mb-1">Donacije prikupljene</Badge>
    }
    else {
        return <Badge variant="success" className="mb-1">Otvoreno</Badge>
    }
}


export default function CampaignCard({ name, dateFrom, dateTo, productNeeds, imageUrl, children, copyUrl }) {
    return (
        <Card>
            <div style={{ position: "relative" }}>
                <Card.Img
                    style={{ objectFit: "contain", height: "200px", width: "100%", backgroundColor: "#000000" }}
                    variant="side"
                    src={imageUrl}
                    className="bg-black"
                />
                { /* <CampaignTypeBadges className="ml-2" style={{ position: "absolute", top: "85%" }} /> */ }
            </div>
            <Card.Body>
                <CampaignStatusBadge dateTo={dateTo} productNeeds={productNeeds} />
                <Card.Title className="font-weight-bold">{truncateText(name, 33)}</Card.Title>
                <Card.Text className="text-secondary">
                    <InfoWithIcon
                        icon={<ClockFill />}
                        heading="Vrijeme trajanja kampanje"
                        value={`${dateToDisplayString(dateFrom)} - ${dateTo ? dateToDisplayString(dateTo) : "..."}`}
                    />
                </Card.Text>
                {children}
            </Card.Body>
        </Card>
    );
}

CampaignCard.propTypes = {
    name: PropTypes.string,
    dateFrom: PropTypes.instanceOf(Date),
    dateTo: PropTypes.instanceOf(Date),
    productNeeds: PropTypes.array,
    imageUrl: PropTypes.string,
    children: PropTypes.node,
    copyUrl: PropTypes.string,
};

CampaignCard.defaultProps = {
    name: "Humanitarni cageball turnir",
    dateFrom: new Date(2022, 8, 1),
    productNeeds: [],
    imageUrl: "https://picsum.photos/600/300",
    children: <div />,
    copyUrl: null,
};
