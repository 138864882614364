import { LOGIN_USER_FAILED, LOGIN_USER_PENDING, LOGIN_USER_DONE, LOGOUT_USER } from "./actionTypes";

export function authReducer(state, { payload, type }) {
    switch (type) {
        case LOGIN_USER_FAILED:
            return { ...state, error: payload, loading: false, succeeded: false, accesToken: null };
        case LOGIN_USER_PENDING:
            return { ...state, loading: true, error: null, succeeded: null, accesToken: null };
        case LOGIN_USER_DONE:
            return { ...state, loading: false, succeeded: true, accessToken: payload };
        case LOGOUT_USER:
            return {
                error: null,
                accessToken: null,
                succeeded: null,
                loading: false,
            };
        default:
            return state;
    }
}
