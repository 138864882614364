import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import { useQueryClient, useMutation } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { Api } from "@donatqr/common-iri";

import { schema } from "../forms/createProductNeedForm";

export default function CreateProductNeedForm() {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const { campaignId, organizationId } = useParams();
    const mutation = useMutation(
        ["product-needs", campaignId],
        (need) => Api.Need.createProductNeed({ ...need, campaignId }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["campaign-needs", campaignId]);
                navigate(`/${organizationId}/campaign/${campaignId}`);
            },
        }
    );

    return (
        <form onSubmit={handleSubmit(mutation.mutateAsync)}>
            <Row>
                <Col xs={12} md={9} lg={7}>
                    <Card className="shadow-sm">
                        <Card.Body>
                            <Form.Group>
                                <Form.Label>Naziv proizvoda</Form.Label>
                                <Form.Control {...register("name")} type="text" placeholder="Kruh" />
                                <small className="text-danger">{errors?.name?.message}</small>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Adresa dostave</Form.Label>
                                <Form.Control
                                    {...register("address")}
                                    type="text"
                                    placeholder="Ul. Ruđera Boškovića 32"
                                />
                                <small className="text-danger">{errors?.address?.message}</small>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    Opis - <i>opcionalno</i>
                                </Form.Label>
                                <Form.Control
                                    {...register("description")}
                                    as="textarea"
                                    rows="5"
                                    maxLength="500"
                                    placeholder="Dodatne informacije"
                                />
                                <small className="text-danger">{errors?.description?.message}</small>
                            </Form.Group>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Potrebna količina</Form.Label>
                                        <Form.Control {...register("goal")} type="number" placeholder="..." />
                                        <small className="text-danger">{errors?.goal?.message}</small>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Mjera</Form.Label>
                                        <select {...register("unit")} className="custom-select">
                                            <option value="kg">kilogram</option>
                                            <option value="l">litra</option>
                                            <option value="kom">komad</option>
                                        </select>
                                        <small className="text-danger">{errors?.unit?.message}</small>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                <Col className="mt-4" xs={12} md={9} lg={7}>
                    <Button variant="primary" className="btn-block" type="submit">
                        Kreiraj
                    </Button>
                </Col>
            </Row>
        </form>
    );
}
