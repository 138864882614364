import axios from "./axios";


export async function login({ identifier, password }) {
    const { data } = await axios.post(
        "/auth/local",
        { identifier, password },
    );
    return data;
}


export async function getMe() {
    const { data } = await axios.get("/users/me");
    return data;
}
