import React, { Fragment } from "react";
import { Outlet } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { Container, Navbar, Nav } from "react-bootstrap";
import { ST_NICHOLAS_ORGANIZATION_ID } from "../consts";


export function CustomNav({ isAdmin }) {
    const navigate = useNavigate();
    const { organizationId, campaignId } = useParams();

    let organizationUrl = "/";
    if (!isAdmin) {
        organizationUrl = `/organization/${organizationId}`;
    }

    const logout = () => {
        window.localStorage.accessToken = "";
        navigate("/login");
    }

    const isStNicholas = (organizationId == ST_NICHOLAS_ORGANIZATION_ID || campaignId == "sveti-nikola");
    const logo = (isStNicholas) ? "/donatqrLogo.png" : "/dvz-logo.png";
    const logoHeight = (isStNicholas) ? 20 : 40;

    return (
        <Navbar bg="white" expand="lg" className="border-bottom">
            <Container>
                <Nav.Link onClick={() => navigate(organizationUrl)}><img height={logoHeight} className="mr-2" src={logo} /></Nav.Link>
                {
                    isAdmin &&
                    <Nav style={{ marginLeft: "auto" }}>
                        <Nav.Link onClick={logout}>Odjava</Nav.Link>
                    </Nav>
                }
                {
                    !isAdmin &&
                    <Nav>
                        <Nav.Link onClick={() => navigate("/summer")}>Summer Special</Nav.Link>
                    </Nav>
                }
            </Container>
        </Navbar>
    );
}


CustomNav.propTypes = {
    isAdmin: PropTypes.bool,
};

CustomNav.defaultProps = {
    isAdmin: false,
};


export function NavLayout({ isAdmin }) {
    return (
        <>
            <CustomNav isAdmin={isAdmin} />
            <Outlet />
        </>
    )
}


NavLayout.propTypes = {
    isAdmin: PropTypes.bool,
};

NavLayout.defaultProps = {
    isAdmin: false,
};
