import React from "react";
import { Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";

function InfoWithIcon({ icon, heading, value }) {
    return (
        <Row>
            <Col className="col-auto pr-0 align-self-top">{icon}</Col>
            <Col xs={9} className="d-inline pl-2">
                <div className="font-weight-bold">{heading}</div>
                <div>{value}</div>
            </Col>
        </Row>
    );
}

InfoWithIcon.propTypes = {
    icon: PropTypes.node.isRequired,
    heading: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
};

export default InfoWithIcon;
