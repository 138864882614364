import React, { Fragment } from "react";
import { Card, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";

import { truncateText } from "../utils";
import { LOREM_IPSUM, EUR_CONVERSION_RATE } from "../consts";
import { eurToHrk } from "../utils/currencies";

export default function MonetaryNeedCard({ name, description, collected, goal, price, children }) {
    const hasPrice = !(price === 1);

    return (
        <Card className="shadow-sm mb-4">
            <Card.Body>
                <Card.Title className="font-weight-bold">{truncateText(name, 40)}</Card.Title>
                <hr />
                <p className="mt-2">{description}</p>
                {description && <hr />}
                <Row>
                    <Col className="pl-3 pr-0">
                        <Row>
                            <Col xs={hasPrice ? 12 : null}>
                                <div className="text-secondary">PRIKUPLJENO</div>
                                <div className="text-success font-weight-bold">
                                    {collected} € / { eurToHrk(collected) } kn
                                </div>
                            </Col>
                            {!hasPrice && <Col className="d-none d-sm-block" xs={3} />}
                            <Col className="mt-2" xs={hasPrice ? 12 : null}>
                                <div className="text-secondary">CILJ</div>
                                <div className="text-warning font-weight-bold">
                                    {goal} € / { eurToHrk(goal) } kn
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={2} className="" />
                    {hasPrice && (
                        <Col className="pr-5 mt-4">
                            <div className="text-secondary">CIJENA</div>
                            {
                            price ? (
                                <>
                                    <div className="lead font-weight-bold">{price} €</div>
                                    <div className="lead font-weight-bold">{eurToHrk(price)} kn</div>
                                </>
                            ) :
                                <div className="lead font-weight-bold">/</div>
                        }

                        </Col>
                    )}
                </Row>
                {children}
            </Card.Body>
        </Card>
    );
}

MonetaryNeedCard.propTypes = {
    name: PropTypes.string,
    description: PropTypes.string,
    collected: PropTypes.number,
    goal: PropTypes.number,
    price: PropTypes.number,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

MonetaryNeedCard.defaultProps = {
    name: "Prijavnica za nogometni turnir",
    description: LOREM_IPSUM,
    collected: 0,
    goal: 0,
    price: 1,
};
