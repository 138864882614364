import axios from "./axios";

export async function uploadFile(file) {
    const formData = new FormData();
    formData.append("files", file, file.name);

    const { data } = await axios.post("/upload", formData);

    return data[0];
}
