import React from "react";
import PropTypes from "prop-types";
import { ListGroup, Col, Row, Form, Button } from "react-bootstrap";
import { XLg } from "react-bootstrap-icons";
import { useController } from "react-hook-form";

function FilePreview({ file, onRemove }) {
    let imgSrc = null;
    try {
        imgSrc = URL.createObjectURL(file);
    } catch (err) {
        imgSrc = file.url;
    }

    return (
        <ListGroup.Item>
            <Row>
                <Col xs={2}>
                    <img
                        style={{ height: 50, width: "auto", margin: "auto", objectFit: "contain" }}
                        className="img-fluid ratio ratio-4x3"
                        src={imgSrc}
                        alt={file.name}
                    />
                </Col>
                <Col className="align-self-center" xs={8}>
                    {file.name}
                </Col>
                <Col xs={2} className="align-self-center">
                    <Button onClick={onRemove} variant="outline-danger" size="sm">
                        <XLg />
                    </Button>
                </Col>
            </Row>
        </ListGroup.Item>
    );
}

FilePreview.propTypes = {
    file: PropTypes.object.isRequired,
    onRemove: PropTypes.func.isRequired,
};

function MultipleFileInput({ name, control }) {
    const { field } = useController({
        name,
        control,
        defaultValue: [],
    });

    const fileAdded = (e) => {
        const newFiles = [...field.value, e.target.files[0]];
        field.onChange(newFiles);
    };

    const fileRemove = (index) => () => {
        const newFiles = [...field.value];
        newFiles.splice(index, 1);
        field.onChange(newFiles);
    };

    const getKey = (file) => file.id || URL.createObjectURL(file);

    return (
        <Form.Group controlId="formFile" className="mb-3">
            <Form.File custom>
                <Form.File.Label>Click to add...</Form.File.Label>
                <Form.Control onChange={fileAdded} multiple type="file" />
                <ListGroup className="d-block mt-4" variant="flush">
                    {field.value.map((file, index) => (
                        <FilePreview key={getKey(file)} file={file} onRemove={fileRemove(index)} />
                    ))}
                </ListGroup>
            </Form.File>
        </Form.Group>
    );
}

MultipleFileInput.propTypes = {
    name: PropTypes.string.isRequired,
    control: PropTypes.object.isRequired,
};

export default MultipleFileInput;
