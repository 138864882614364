import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Button, Dropdown, ButtonGroup } from "react-bootstrap";
import { Components } from "@donatqr/common-iri";
import PropTypes from "prop-types";

import RemoveCampaignModal from "./RemoveCampaignModal";

export default function CampaignCard(props) {
    const navigate = useNavigate();
    const { campaignId, organizationId, name } = props;

    const [removeCampaignModalProps, setRemoveCampaignModalProps] = useState({ show: false });

    const handleClose = () => setRemoveCampaignModalProps({ show: false });
    const handleShow = () => () =>
        setRemoveCampaignModalProps({
            show: true,
            campaignId,
            campaignName: name,
        });

    const copyCampaignUrl = () => {
        const { DQR_USER_BASE_URL } = window.__RUNTIME_CONFIG__;
        navigator.clipboard.writeText(`${DQR_USER_BASE_URL}/organization/${organizationId}/campaign/${campaignId}`);
    };

    return (
        <Components.CampaignCard {...props}>
            <Row>
                <Col xs={6} md={5}>
                    <Button
                        variant="primary"
                        className="btn-block"
                        onClick={() => navigate(`/${organizationId}/campaign/${campaignId}/donations`)}
                    >
                        Admin
                    </Button>
                </Col>
                <Col className="col-auto" style={{ marginLeft: "auto" }}>
                    <Dropdown as={ButtonGroup}>
                        <Dropdown.Toggle variant="secondary">Akcije</Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => navigate(`/${organizationId}/campaign/${campaignId}`)}>
                                Pregledaj
                            </Dropdown.Item>
                            <Dropdown.Item href="#/action-2">Generiraj izvješće</Dropdown.Item>
                            <Dropdown.Item onClick={copyCampaignUrl}>Kopiraj URL</Dropdown.Item>
                            <Dropdown.Item onClick={() => navigate(`/${organizationId}/campaign/${campaignId}/edit`)}>
                                Uredi
                            </Dropdown.Item>
                            <Dropdown.Item onClick={handleShow(campaignId, name)}>Izbriši</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
            </Row>
            <RemoveCampaignModal {...removeCampaignModalProps} handleClose={handleClose} />
        </Components.CampaignCard>
    );
}

CampaignCard.propTypes = {
    campaignId: PropTypes.number.isRequired,
    organizationId: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
};
