import { useState, useEffect} from "react";
import { useQuery } from "react-query";
import { Campaign } from "../dqrApi";

export default function useCampaignsList(organizationId) {
    const [currentPage, setCurrentPage] = useState(1);
    const [pageCount, setPageCount] = useState(1);

    const { data: campaignsResponseData } = useQuery(
        ["campaigns", currentPage],
        () => Campaign.getCampaignsByOrganizationId({ organizationId, pageNumber: currentPage}),
        {
            enabled: !!organizationId,
            onSuccess: (data) => {
                setPageCount(data.paginationInfo.pageCount);
            }
        }
    );

    useEffect(() => {
        setPageCount(campaignsResponseData?.paginationInfo?.pageCount || 1);
    }, []);

    const campaigns = campaignsResponseData?.campaigns || [];

    return {
        campaigns,
        page: currentPage,
        pageCount: pageCount,
        setPage: setCurrentPage,
    }
}
