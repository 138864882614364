import React, { Fragment } from "react";
import { Container, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";

export default function Basket({ campaignName, children }) {
    return (
        <>
            <Container className="mb-2">
                <h1 className="pt-5 font-weight-bold">{campaignName}</h1>
                <Row className="mt-4">
                    {children}
                </Row>
            </Container>
        </>
    );
}

Basket.propTypes = {
    campaignName: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

Basket.defaultProps = {
    campaignName: "Učitavam...",
};
