import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import { GeoAltFill, Calendar2WeekFill } from "react-bootstrap-icons";
import PropTypes from "prop-types";

import { truncateText, dateToDisplayString } from "../utils";
import InfoWithIcon from "./InfoWithIcon";
import { LOREM_IPSUM } from "../consts";

export default function VolunteeringNeedCard({
    name,
    description,
    location,
    date,
    hourFrom,
    hourTo,
    goal,
    registered,
    children,
}) {
    return (
        <Card className="shadow-sm mb-4">
            <Card.Body>
                <Card.Title className="font-weight-bold">{truncateText(name, 40)}</Card.Title>
                <div className="lead text-secondary">
                    <InfoWithIcon icon={<GeoAltFill />} heading="Adresa događaja" value={location} />
                </div>
                <div className="lead text-secondary mt-1">
                    <InfoWithIcon
                        icon={<Calendar2WeekFill />}
                        heading="Vrijeme događaja"
                        value={`${dateToDisplayString(date)} ${hourFrom} - ${hourTo}`}
                    />
                </div>
                { description && <hr /> }
                <p className="mt-2">{description}</p>
                <hr />
                <Row>
                    <Col>
                        <div className="text-secondary">PRIJAVLJENO</div>
                        <div className="text-success font-weight-bold">{registered}</div>
                    </Col>
                    <Col xs={1} className="d-none d-sm-block" />
                    <Col>
                        <div className="text-secondary">POTREBNO</div>
                        <div className="text-warning font-weight-bold">{goal}</div>
                    </Col>
                </Row>
                {children}
            </Card.Body>
        </Card>
    );
}

VolunteeringNeedCard.propTypes = {
    name: PropTypes.string,
    description: PropTypes.string,
    location: PropTypes.string,
    goal: PropTypes.number,
    registered: PropTypes.number,
    date: PropTypes.instanceOf(Date),
    hourFrom: PropTypes.string,
    hourTo: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

VolunteeringNeedCard.defaultProps = {
    name: "Konobar",
    description: LOREM_IPSUM,
    location: "Dobrilina 7, Split",
    goal: 51,
    registered: 100,
    date: new Date(2022, 8, 1),
    hourFrom: "10:30",
    hourTo: "12:30",
};
