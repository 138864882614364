import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { Api } from "@donatqr/common-iri";

export default function useDonationCollectionBulkEdit({ donationType, campaignId }) {
    const { control: controlSelectedCollections, watch: watchSelectedCollections } = useForm();

    const selectedCollectionsCount = Object.values(watchSelectedCollections()).reduce(
        (sum, isSelected) => sum + Number(isSelected),
        0
    );

    const queryClient = useQueryClient();

    const mutation = useMutation(
        ["donation-collections", donationType, campaignId],
        (newStatus) => {
            const selectedCollections = watchSelectedCollections();
            const changeStatuses = Object.entries(selectedCollections)
                .filter(([_, isSelected]) => isSelected)
                .map(([id, _]) =>
                    Api.Donation.updateDonationCollectionStatus({
                        donationCollectionId: id,
                        status: newStatus,
                        donationType,
                    })
                );

            return Promise.all(changeStatuses);
        },
        {
            onSuccess: () => queryClient.invalidateQueries(["donation-collections", donationType]),
        }
    );

    return {
        controlSelectedCollections,
        selectedCollectionsCount,
        mutation,
    };
}
