import React from "react";
import { Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
import PropTypes from "prop-types";

export default function BadgeWithTooltip({ message, children, ...props }) {
    return (
        <OverlayTrigger placement="bottom" overlay={<Tooltip>{message}</Tooltip>}>
            <Badge {...props}>{children}</Badge>
        </OverlayTrigger>
    );
}

BadgeWithTooltip.propTypes = {
    children: PropTypes.node.isRequired,
    message: PropTypes.string.isRequired,
};
