import React from "react";
import PropTypes from "prop-types";
import { Carousel } from "react-bootstrap";

function imageFormat(imageUrls, altTemplate) {
    return imageUrls.map((url, index) => ({
        src: url,
        alt: `${altTemplate} (${index})`,
    }));
}

function ImageGallery({ imageUrls, altTemplate, height }) {
    return (
        <Carousel style={{ background: "black" }}>
            {imageFormat(imageUrls, altTemplate).map((img) => (
                <Carousel.Item key={img.src}>
                    <div className="d-flex justify-content-center">
                        <img
                            style={{ height, width: "auto", margin: "auto", objectFit: "contain" }}
                            className="img-fluid ratio ratio-4x3"
                            src={img.src}
                            alt={img.alt}
                        />
                    </div>
                </Carousel.Item>
            ))}
        </Carousel>
    );
}

ImageGallery.propTypes = {
    imageUrls: PropTypes.arrayOf(PropTypes.string).isRequired,
    altTemplate: PropTypes.string.isRequired,
    height: PropTypes.string.isRequired,
};

export { ImageGallery };
export default ImageGallery;
