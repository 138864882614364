import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Tab, Nav } from "react-bootstrap";
import { CashStack, Cart3, People } from "react-bootstrap-icons";

// eslint-disable-next-line
export function MonetaryTab({ children, disable }) {
    return <Tab.Pane eventKey="monetary">{children}</Tab.Pane>;
}

// eslint-disable-next-line
export function ProductTab({ children, disable }) {
    return <Tab.Pane eventKey="product">{children}</Tab.Pane>;
}

// eslint-disable-next-line
export function VolunteeringTab({ children, disable }) {
    return <Tab.Pane eventKey="volunteering">{children}</Tab.Pane>;
}

export function Tabs({ children }) {
    const monetaryTab = children.find((child) => child?.type === MonetaryTab);
    const productTab = children.find((child) => child?.type === ProductTab);
    const volunteeringTab = children.find((child) => child?.type === VolunteeringTab);

    const [activeKey, setActiveKey] = useState(null);

    useEffect(() => {
        if (activeKey) return;
        if (monetaryTab) setActiveKey("monetary");
        else if (productTab) setActiveKey("product");
        else if (volunteeringTab) setActiveKey("volunteering");
    }, [children]);

    const changeKey = (key) => () => setActiveKey(key);

    return (
        <Tab.Container activeKey={activeKey}>
            <Nav className="mb-4 h4" as="ul">
                {monetaryTab && (
                    <Nav.Item as="li" onClick={changeKey("monetary")}>
                        <Nav.Link className="p-0 mr-4" eventKey="monetary">
                            <CashStack className="mb-1 mr-2" />
                            Monetarne
                        </Nav.Link>
                    </Nav.Item>
                )}
                {productTab && (
                    <Nav.Item as="li" onClick={changeKey("product")}>
                        <Nav.Link className="p-0 mr-4" eventKey="product">
                            <Cart3 className="mb-1 mr-2" />
                            Potrepštine
                        </Nav.Link>
                    </Nav.Item>
                )}
                {volunteeringTab && (
                    <Nav.Item as="li" onClick={changeKey("volunteering")}>
                        <Nav.Link className="p-0" eventKey="volunteering">
                            <People className="mb-1 mr-2" />
                            Volonteri
                        </Nav.Link>
                    </Nav.Item>
                )}
            </Nav>
            <Tab.Content>{children}</Tab.Content>
        </Tab.Container>
    );
}

Tabs.propTypes = {
    children: PropTypes.arrayOf(PropTypes.node).isRequired,
};
