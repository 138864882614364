import React from "react";
import PropTypes from "prop-types";
import { Modal, Button, Form } from "react-bootstrap";
import { Consts, Utils } from "@donatqr/common-iri";

import useDonations from "../hooks/useDonations";

export default function VolunteeringDonationDetails({ show, handleClose, donationCollectionId }) {
    const { donationCollection, donations, mutation, changeDonationCollectionStatus, donationCollectionStatus } =
        useDonations({
            donationCollectionId,
            enabled: show,
            donationType: "volunteering",
        });

    const collectionData = donationCollection?.attributes || {};
    const donation = (donations || [])[0];
    const need = donation?.attributes?.need?.data?.attributes;

    return (
        <Modal show={show} onHide={handleClose} animation={false}>
            <Modal.Header closeButton>
                <Modal.Title>Detalji</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Label>Status</Form.Label>
                <select
                    value={donationCollectionStatus}
                    onChange={changeDonationCollectionStatus}
                    className="custom-select mt-2"
                >
                    {Object.entries(Consts.Donations.VOLUNTEERING_DONATION_LABEL_BY_STATUS).map(([key, status]) => (
                        <option key={key} value={key}>
                            {status}
                        </option>
                    ))}
                </select>
                <hr />
                <span className="text-primary">Informacije o volonteru</span>
                <div className="mt-2">
                    <span className="text-secondary">Ime i prezime</span>
                    <span className="font-weight-bold float-right">{collectionData?.full_name}</span>
                </div>
                <div className="mt-1">
                    <span className="text-secondary">OIB</span>
                    <span className="font-weight-bold float-right">{collectionData.oib}</span>
                </div>
                <div className="mt-1">
                    <span className="text-secondary">Spol</span>
                    <span className="font-weight-bold float-right">{collectionData.gender}</span>
                </div>
                <div className="mt-1">
                    <span className="text-secondary">Broj godina</span>
                    <span className="font-weight-bold float-right">{collectionData.age}</span>
                </div>
                <div className="mt-1">
                    <span className="text-secondary">Državljanstvo</span>
                    <span className="font-weight-bold float-right">{collectionData.nationality}</span>
                </div>
                <div className="mt-1">
                    <span className="text-secondary">Broj mobitela</span>
                    <span className="font-weight-bold float-right">{collectionData.phone_number}</span>
                </div>
                <div className="mt-1">
                    <span className="text-secondary">E-mail</span>
                    <span className="font-weight-bold float-right">{collectionData.email}</span>
                </div>
                <hr />
                <span className="text-primary">Informacije o prijavi</span>
                <div className="mt-2">
                    <span className="text-secondary">Vrijeme prijave</span>
                    <span className="font-weight-bold float-right">
                        {Utils.dateTimeToString(new Date(collectionData?.createdAt))}
                    </span>
                </div>
                <div className="mt-1">
                    <span className="text-secondary">Pozicija</span>
                    <span className="font-weight-bold float-right">{need?.base?.name}</span>
                </div>
                <div className="mt-1">
                    <span className="text-secondary">Lokacija volontiranja</span>
                    <span className="font-weight-bold float-right">{need?.address}</span>
                </div>
                <div className="mt-1">
                    <span className="text-secondary">Vrijeme volontiranja</span>
                    <span className="font-weight-bold float-right">
                        {need?.date} {need?.start_hour} - {need?.end_hour}
                    </span>
                </div>
                <div className="mt-1">
                    <span className="text-secondary">Prijašnje iskustvo</span>
                    <span className="font-weight-bold float-right">
                        {collectionData.has_volunteering_experience ? "DA" : "NE"}
                    </span>
                </div>
                <hr />
                <span className="text-primary">Prijašnje iskustvo</span>
                <div className="mt-1">{collectionData.volunteering_experience}</div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Odustani
                </Button>
                <Button
                    onClick={async () => {
                        await mutation.mutateAsync();
                        handleClose();
                    }}
                >
                    Spremi
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

VolunteeringDonationDetails.propTypes = {
    show: PropTypes.bool,
    handleClose: PropTypes.func,
    donationCollectionId: PropTypes.string,
};

VolunteeringDonationDetails.defaultProps = {
    show: false,
    handleClose: () => console.log("CLOSING"),
    donationCollectionId: null,
};
