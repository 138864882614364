import React from "react";
import { Container } from "react-bootstrap";


export default function EmptyPage() {
    return (
        <Container
            className="d-flex justify-content-center align-items-center"
            style={{ height: "90vh" }}
        >
            <div className="display-1 font-weight-bold">
                <p>
                    4<span className="text-primary">0</span>4
                </p>
                <p className="text-secondary h1">
                    Nažalost ova stranica ne postoji 😓.
                    <br/>
                    Za više informacija posjetite <a href="https://zaklada.iri.hr/home/volonterstvo/">zaklada.iri.hr</a>
                </p>
            </div>
        </Container>
    );
}
