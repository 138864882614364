import axios from "./axios";
import { uploadFile } from "./files";
import { v4 as uuid4 } from "uuid";
import { ProductTab } from "../components/CampaignTypeNav";

const COLLECTION_URL_BY_DONATION_TYPE = {
    "monetary": "/monetary-donation-collections",
    "product": "/product-donation-collections",
    "volunteering": "/volunteering-donation-collections"
}

const DONATION_URL_BY_DONATION_TYPE = {
    "monetary": "/monetary-donations",
    "product": "/product-donations",
    "volunteering": "/volunteering-donations",
}


export async function getDonationCollections({ campaignId, organizationId, donationType, page }) {
    const url = COLLECTION_URL_BY_DONATION_TYPE[donationType];

    let params = {
        "pagination[pageSize]": 100,
        "pagination[page]": page || 1,
        // "pagination[pageSize]": 20,
        "sort": "id",
    };

    if (campaignId) {
        params["filters[campaign][id]"] = campaignId
    }

    if (organizationId) {
        params["filters[campaign][organization][id]"] = organizationId;
        params["populate[campaign]"] = 1;
    }

    if (donationType === "monetary") {
        params.populate = "company_info";
    }

    if (donationType === "volunteering") {
        params["populate[donations][populate][need][populate][base]"] = 1;
    }

    const { data } = await axios.get(url, { params });

    if (!page) { // TODO: implement pagination on all dashboards
        return data.data;
    }

    return { donationCollections: data.data, paginationInfo: data.meta.pagination };
}


export async function getDonationCollectionBySecret({ donationCollectionSecret, donationType }) {
    let url = COLLECTION_URL_BY_DONATION_TYPE.monetary;

    if (donationType) {
        url = COLLECTION_URL_BY_DONATION_TYPE[donationType];
    }


    const { data } = await axios.get(url, {
        params: {
            "populate[donations][populate][need][populate][base]": 1,
            "populate[campaign]": 1,
            "filters[secret]": donationCollectionSecret,
        }
    });

    return data.data[0];
}


export async function getDonationCollection({ donationCollectionId, donationType }) {
    const url = COLLECTION_URL_BY_DONATION_TYPE[donationType];

    const { data } = await axios.get(`${url}/${donationCollectionId}`, {
        params: {
            populate: "company_info,donations,donation_proof",
        },
    });

    const need = data.data;
    return need;
}


export async function getDonations({ collectionId, donationType }) {
    const url = DONATION_URL_BY_DONATION_TYPE[donationType];

    const { data } = await axios.get(url, {
        params: {
            "populate[need][populate][base]": 1,
            "filters[donation_collection][id]": collectionId,
        }
    });

    return data.data;
}


export async function updateDonationQuantity({ donationId, quantity, donationType }) {
    const url = DONATION_URL_BY_DONATION_TYPE[donationType];

    if (quantity === 0) {
        const { data } = await axios.delete(`${url}/${donationId}`);
        return data;
    }

    const { data } = await axios.put(`${url}/${donationId}`, {
        data: {
            quantity: quantity
        }
    });

    return data;
}


export async function updateDonationCollectionStatus({ donationCollectionId, status, donationType }) {
    const url = COLLECTION_URL_BY_DONATION_TYPE[donationType];

    const { data } = await axios.put(
        `${url}/${donationCollectionId}`, {
            data: {
                status: status,
            }
        }
    );

    return data;
}


export async function uploadDonationProof({ donationCollectionSecret, file }) {
    const url = COLLECTION_URL_BY_DONATION_TYPE["monetary"];

    const { data: collections } = await axios.get(`${url}`, {
        params: {
            "filters[secret]": donationCollectionSecret,
        }
    });

    const donationCollection = collections.data[0];

    if (!donationCollection?.id) {
        return;
    }

    const fileData = await uploadFile(file);

    const { data: updatedCollection } = await axios.put(`${url}/${donationCollection.id}`, {
        data: {
            donation_proof: fileData.id,
        }
    });

    return { updatedCollection };
}


async function createDonations({donations, donationType}) {
    const url = DONATION_URL_BY_DONATION_TYPE[donationType];
    const createDonations = donations.map((donation) => {
        const payload = {
            need: donation.need.id,
        }
        if (donationType !== "volunteering") {
            payload.quantity = donation.quantity;
        }
        return axios.post(url, { data: payload });
    });

    const createdDonations = await Promise.all(createDonations);
    return createdDonations.map((donation) => donation.data);
}


export async function createMonetaryDonation({ donations, donatorData, campaignId }) {
    const monetaryDonations = await createDonations({
        donations,
        donationType: "monetary"
    });

    const collectionDonatorData = {
        full_name: donatorData.nameAndSurname,
        phone_number: donatorData.phoneNumber,
        email: donatorData.email,
        campaign: campaignId,
        donations: monetaryDonations.map((donation) => donation.data.id),
        secret: uuid4(),
    };

    if (donatorData.isCompany) {
        const collectionCompanyData = {
            name: donatorData.companyName,
            oib: donatorData.companyId,
            address: donatorData.companyAddress,
            responsible_person: donatorData.responsiblePerson,
        };
        collectionDonatorData.company_info = collectionCompanyData;
    }

    await axios.post("/monetary-donation-collections", {
        data: collectionDonatorData
    });

    return monetaryDonations;
}


export async function createProductDonation({ donations, donatorData, campaignId }) {
    const productDonations = await createDonations({
        donations,
        donationType: "product",
    });

    await axios.post("/product-donation-collections", {
        data: {
            full_name: donatorData.nameAndSurname,
            date_of_birth: donatorData.birthDate,
            phone_number: donatorData.phoneNumber,
            email: donatorData.email,
            campaign: campaignId,
            donations: productDonations.map((donation) => donation.data.id),
            secret: uuid4(),
        }
    });

    return productDonations;
}


export async function createVolunteeringDonation({ donations, donatorData,  campaignId}) {
    const volunteeringDonations = await createDonations({
        donations,
        donationType: "volunteering",
    });

    await axios.post("/volunteering-donation-collections", {
        data: {
            full_name: donatorData.nameAndSurname,
            oib: donatorData.personId,
            gender: donatorData.gender,
            age: donatorData.age,
            nationality: donatorData.nationality,
            phone_number: donatorData.phoneNumber,
            email: donatorData.email,
            has_volunteering_experience: donatorData.hasExperience,
            volunteering_experience: donatorData.experience,
            campaign: campaignId,
            donations: volunteeringDonations.map((donation) => donation.data.id),
            secret: uuid4(),
        },
    });
}
