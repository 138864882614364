import React from "react";
import { CashStack, Cart3, People } from "react-bootstrap-icons";
import PropTypes from "prop-types";

import Badge from "./BadgeWithTooltip";

export default function CampaignTypeBadges({ isMonetary, isDonation, isVolunteering, ...props }) {
    return (
        <div {...props}>
            {isMonetary && (
                <Badge message="Monetarna kampanja" className="badge-light mr-2">
                    <CashStack />
                </Badge>
            )}
            {isDonation && (
                <Badge message="Donacijska kampanja" className="badge-light mr-2">
                    <Cart3 />
                </Badge>
            )}
            {isVolunteering && (
                <Badge message="Volonterska kampanja" className="badge-light mr-2">
                    <People />
                </Badge>
            )}
        </div>
    );
}

CampaignTypeBadges.propTypes = {
    isMonetary: PropTypes.bool,
    isDonation: PropTypes.bool,
    isVolunteering: PropTypes.bool,
};

CampaignTypeBadges.defaultProps = {
    isMonetary: true,
    isDonation: true,
    isVolunteering: true,
};
