import axios from "./axios";
import { mapDateToHours, unwrapNeed } from "./mappers";
import { dateToApiString } from "../utils/dateToString";


export async function createMonetaryNeed({
    name,
    description,
    goal,
    donationSize,
    campaignId,
}){
    const { data } = await axios.post("/monetary-needs", {
        data: {
            base: {
                name: name,
                description: description,
                goal: goal,
            },
            minimal_donation: donationSize,
            campaign: campaignId,
        }
    });
return data;
}


export async function createProductNeed({
    name,
    description,
    goal,
    address,
    unit,
    campaignId,
}){
    const { data } = await axios.post("/product-needs", {
        data: {
            base: {
                name: name,
                description: description,
                goal: goal,
            },
            drop_off_location: address,
            unit: unit,
            campaign: campaignId,
        }
    });

    return data;
}


export async function createVolunteeringNeed({
    name,
    description,
    goal,
    address,
    eventDate,
    eventTimeStart,
    eventTimeEnd,
    campaignId,
}){
    console.log({eventDate});
    const { data } = await axios.post("/volunteering-needs", {
        data: {
            base: {
                name: name,
                description: description,
                goal: goal,
            },
            address: address,
            date: dateToApiString(eventDate),
            start_hour: mapDateToHours(eventTimeStart),
            end_hour: mapDateToHours(eventTimeEnd),
            campaign: campaignId,
        }
    });

    return data;
}


export async function getNeeds({ campaignId }){
    const params = {
        params: {
            "populate[base]": 1,
            "populate[donations][filters][donation_collection][status][$in][0]": "pending",
            "populate[donations][filters][donation_collection][status][$in][1]": "accepted",
            "populate[donations][filters][donation_collection][status][$in][2]": "delivered",
            "populate[donations][filters][donation_collection][status][$in][3]": "participated",
            "filters[campaign]": campaignId,
        },
    };

    const getMonetaryNeeds = axios.get("/monetary-needs", params);
    const getProductNeeds = axios.get("/product-needs", params);
    const getVolunteeringNeeds = axios.get("/volunteering-needs", params);

    const [ {data: monetaryNeeds}, {data: productNeeds}, { data: volunteeringNeeds } ] = await Promise.all(
        [getMonetaryNeeds, getProductNeeds, getVolunteeringNeeds]
    );

    return {
        monetary: monetaryNeeds.data.map(unwrapNeed),
        product: productNeeds.data.map(unwrapNeed),
        volunteering: volunteeringNeeds.data.map(unwrapNeed),
    };
}


const NEED_URL_BY_TYPE = {
    monetary: "/monetary-needs",
    product: "/product-needs",
    volunteering: "/volunteering-needs",
};


export async function removeNeed({ needId, needType }) {
    const { data } = await axios.delete(`${NEED_URL_BY_TYPE[needType]}/${needId}`);
    return data.data;
}
