import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { Table, Form, Card, Row, Col, Dropdown, Button } from "react-bootstrap";
import { PencilFill } from "react-bootstrap-icons";
import { useController, useForm } from "react-hook-form";
import { Consts, Api, Utils } from "@donatqr/common-iri";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";

import VolunteeringDonationDetails from "./VolunteeringDonationDetails";
import useDonationCollectionBulkEdit from "../hooks/useDonationCollectionBulkEdit";

export function VolunteeringDonationTableRow({ control, name, donorName, status, roleName, location, onDetailsClick }) {
    const { field } = useController({
        name,
        control,
        defaultValue: false,
    });

    return (
        <tr className="p-3">
            <td>
                <Form.Check className="d-inline mr-4" type="checkbox" checked={field.value} onChange={field.onChange} />
            </td>
            <td>{donorName}</td>
            <td>{Consts.Donations.VOLUNTEERING_DONATION_STATUS_BADGES[status]}</td>
            <td>{roleName}</td>
            <td>{location}</td>
            <td>
                <Button variant="light" size="sm" onClick={onDetailsClick}>
                    <PencilFill />
                </Button>
            </td>
        </tr>
    );
}

VolunteeringDonationTableRow.propTypes = {
    control: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    donorName: PropTypes.string,
    status: PropTypes.oneOf(Object.keys(Consts.Donations.VOLUNTEERING_DONATION_LABEL_BY_STATUS)),
    roleName: PropTypes.string,
    location: PropTypes.string,
    onDetailsClick: PropTypes.func,
};

VolunteeringDonationTableRow.defaultProps = {
    donorName: "John Doe",
    status: "2",
    roleName: "Fotograf",
    location: "Dobrilina 7, Split",
    onDetailsClick: () => console.log("DETAILS"),
};

export function VolunteeringDonationTable() {
    const { register: registerFilter, watch: watchFilters } = useForm();

    const [detailsModalState, setModalState] = useState({ show: false, donationCollectionId: null });
    const handleCloseModal = () => {
        setModalState({ ...detailsModalState, show: false });
    };

    const { campaignId } = useParams();
    const { data: donationCollections } = useQuery(["donation-collections", "volunteering", campaignId], () =>
        Api.Donation.getDonationCollections({ campaignId, donationType: "volunteering" })
    );

    const { controlSelectedCollections, selectedCollectionsCount, mutation } = useDonationCollectionBulkEdit({
        donationType: "volunteering",
        campaignId,
    });

    const filteredDonationCollections = Utils.needs.filterDonationCollections(
        donationCollections || [],
        watchFilters()
    );

    return (
        <>
            <Card className="shadow-sm">
                <Card.Body>
                    <Row>
                        <Col sm={12} md={6}>
                            <Form.Group>
                                <Form.Label>Ime i prezime</Form.Label>
                                <Form.Control {...registerFilter("name")} type="text" placeholder="John Doe" />
                            </Form.Group>
                        </Col>
                        <Col sm={12} md={6}>
                            <Form.Group>
                                <Form.Label>Status</Form.Label>
                                <select {...registerFilter("status")} className="custom-select">
                                    <option value="">Odaberite status...</option>
                                    {Object.entries(Consts.Donations.VOLUNTEERING_DONATION_LABEL_BY_STATUS).map(
                                        ([key, status]) => (
                                            <option key={key} value={key}>
                                                {status}
                                            </option>
                                        )
                                    )}
                                </select>
                            </Form.Group>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <Card className="shadow-sm mt-4 mb-4">
                <Card.Body>
                    <Form.Label className="">Grupno uređivanje</Form.Label>
                    <Dropdown className="d-inline-block ml-3">
                        <Dropdown.Toggle variant="secondary" id="dropdown-basic" disabled={!selectedCollectionsCount}>
                            <PencilFill className="mr-1" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item
                                onClick={() =>
                                    mutation.mutateAsync(Consts.Donations.VOLUNTEERING_DONATION_STATUSES.pending)
                                }
                            >
                                Označeni kao nepotvrđeno
                            </Dropdown.Item>
                            <Dropdown.Item
                                onClick={() =>
                                    mutation.mutateAsync(Consts.Donations.VOLUNTEERING_DONATION_STATUSES.accepted)
                                }
                            >
                                Označeni potvrdili dolazak
                            </Dropdown.Item>
                            <Dropdown.Item
                                onClick={() =>
                                    mutation.mutateAsync(Consts.Donations.VOLUNTEERING_DONATION_STATUSES.participated)
                                }
                            >
                                Označeni prisustovali
                            </Dropdown.Item>
                            <Dropdown.Item
                                onClick={() =>
                                    mutation.mutateAsync(
                                        Consts.Donations.VOLUNTEERING_DONATION_STATUSES.did_not_participate
                                    )
                                }
                            >
                                Označeni nisu prisustovali
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Card.Body>
                <Table hover responsive>
                    <thead>
                        <tr>
                            <th>Odaberi</th>
                            <th>Ime i prezime</th>
                            <th>Status</th>
                            <th>Pozicija</th>
                            <th>Lokacija</th>
                            <th>Detalji</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredDonationCollections.map((collection) => {
                            const donation = collection.attributes.donations.data[0].attributes;
                            const need = donation?.need?.data?.attributes;
                            return (
                                <VolunteeringDonationTableRow
                                    key={collection.id}
                                    name={collection.id.toString()}
                                    donorName={collection.attributes.full_name}
                                    status={collection.attributes.status}
                                    roleName={need?.base?.name}
                                    location={need?.address}
                                    control={controlSelectedCollections}
                                    onDetailsClick={() =>
                                        setModalState({ show: true, donationCollectionId: collection.id.toString() })
                                    }
                                />
                            );
                        })}
                    </tbody>
                </Table>
                <VolunteeringDonationDetails {...detailsModalState} handleClose={handleCloseModal} />
            </Card>
        </>
    );
}
