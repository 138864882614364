export function dateToDisplayString(date) {
    return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
}

export function dateToApiString(date) {
    const monthString = `0${date.getMonth() + 1}`.slice(-2);
    const dayString = `0${date.getDate()}`.slice(-2);
    return `${date.getFullYear()}-${monthString}-${dayString}`;
}

export function dateTimeToString(date) {
    const dateString = `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;

    const hoursString = `0${date.getHours()}`.slice(-2);
    const minutesString = `0${date.getMinutes()}`.slice(-2);
    const timeString = `${hoursString}:${minutesString}`;

    return `${dateString} ${timeString}`;
}
