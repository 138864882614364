import * as yup from "yup";

export const schema = yup.object({
    email: yup.string().email("Potrebna je email adresa").required("Potrebna je email adresa"),
    password: yup
        .string()
        .required("Potrebna je lozinka")
        .min(6, "Lozinka mora biti duljine najmanje 6 znakova")
        .max(20, "Lozinka mora biti duljine najviše 20 znakova"),
});
