import * as yup from "yup";

export const schema = yup.object({
    name: yup.string().required("Potreban je naziv pozicije"),
    description: yup.string().notRequired().max(500, "Opis mora biti duljine najviše 500 znakova"),
    address: yup.string().required("Potrebna je adresa održavanja akcije"),
    eventDate: yup.date().nullable().required("Potreban je datum održavanja akcije"),
    eventTimeStart: yup.date().nullable().required("Potrebno je vrijeme početka"),
    eventTimeEnd: yup
        .date()
        .nullable()
        .required("Potrebno je vrijeme kraja")
        .when("eventTimeStart", (eventTimeStart, eventTimeEndSchema) =>
            eventTimeStart
                ? eventTimeEndSchema.min(eventTimeStart, "Vrijeme kraja ne može biti manje od vremena početka")
                : eventTimeEndSchema
        ),
    goal: yup
        .number()
        .integer("Broj volontera mora biti cijeli broj")
        .typeError("Broj volontera mora biti cijeli broj")
        .required("Potreban je broj volontrskih mjesta"),
});
