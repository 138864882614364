import React, { Fragment } from "react";
import { Container } from "react-bootstrap";
import { Components } from "@donatqr/common-iri";

import CreateMonetaryNeedForm from "../components/CreateMonetaryNeedForm";
import CreateProductNeedForm from "../components/CreateProductNeedForm";
import CreateVolunteeringNeedForm from "../components/CreateVolunteeringNeedForm";

export default function CreateNeed() {
    return (
        <>
            <Container className="bg-white mb-2 shadow-sm" fluid>
                <Container className="pb-2">
                    <h1 className="pt-5 mb-4 font-weight-bold">Dodaj potrebu</h1>
                </Container>
            </Container>
            <Container className="mb-5 pt-4">
                <Components.CampaignTypeNav.Tabs>
                    <Components.CampaignTypeNav.MonetaryTab>
                        <CreateMonetaryNeedForm />
                    </Components.CampaignTypeNav.MonetaryTab>
                    <Components.CampaignTypeNav.ProductTab>
                        <CreateProductNeedForm />
                    </Components.CampaignTypeNav.ProductTab>
                    <Components.CampaignTypeNav.VolunteeringTab>
                        <CreateVolunteeringNeedForm />
                    </Components.CampaignTypeNav.VolunteeringTab>
                </Components.CampaignTypeNav.Tabs>
            </Container>
        </>
    );
}
