import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Row, Col, Card, Form, Button, InputGroup } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import { Api } from "@donatqr/common-iri";

import { schema } from "../forms/createMonetaryNeedForm";

export default function CreateMonetaryNeedForm() {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const { campaignId, organizationId } = useParams();
    const mutation = useMutation(
        ["monetary-needs", campaignId],
        (need) => Api.Need.createMonetaryNeed({ ...need, campaignId }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["campaign-needs", campaignId]);
                navigate(`/${organizationId}/campaign/${campaignId}`);
            },
        }
    );

    return (
        <form onSubmit={handleSubmit(mutation.mutateAsync)}>
            <Row>
                <Col xs={12} md={9} lg={7}>
                    <Card className="shadow-sm">
                        <Card.Body>
                            <Form.Group>
                                <Form.Label>Naziv donacije</Form.Label>
                                <Form.Control {...register("name")} type="text" placeholder="Novac za..." />
                                <small className="text-danger">{errors?.name?.message}</small>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    Opis - <i>opcionalno</i>
                                </Form.Label>
                                <Form.Control
                                    {...register("description")}
                                    as="textarea"
                                    rows="5"
                                    maxLength="500"
                                    placeholder="Dodatne informacije"
                                />
                                <small className="text-danger">{errors?.description?.message}</small>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Cilj</Form.Label>
                                <InputGroup>
                                    <Form.Control {...register("goal")} type="number" placeholder="..." />
                                    <div className="input-group-append">
                                        <InputGroup.Text>€</InputGroup.Text>
                                    </div>
                                </InputGroup>
                                <small className="text-danger">{errors?.goal?.message}</small>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    Veličina jedne donacije - <i>opcionalno</i>
                                </Form.Label>
                                <InputGroup>
                                    <Form.Control {...register("donationSize")} type="number" placeholder="..." />
                                    <div className="input-group-append">
                                        <InputGroup.Text>€</InputGroup.Text>
                                    </div>
                                </InputGroup>
                                <small className="text-danger">{errors?.donationSize?.message}</small>
                            </Form.Group>
                        </Card.Body>
                    </Card>
                </Col>
                <Col className="mt-4" xs={12} md={9} lg={7}>
                    <Button variant="primary" className="btn-block" type="submit" disabled={mutation.isLoading}>
                        Kreiraj
                    </Button>
                </Col>
            </Row>
        </form>
    );
}
