import React from "react";
import { Modal, Button } from "react-bootstrap";
import PropTypes from "prop-types";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { Api } from "@donatqr/common-iri";

export default function RemoveNeedModal({ show, handleClose, needId, needName, needType }) {
    const { campaignId } = useParams();

    const queryClient = useQueryClient();

    const mutation = useMutation(["campaign-needs", campaignId], () => Api.Need.removeNeed({ needId, needType }), {
        onSuccess: () => {
            queryClient.invalidateQueries(["campaign-needs", campaignId]);
            handleClose();
        },
    });

    return (
        <Modal show={show} onHide={handleClose} animation={false}>
            <Modal.Header closeButton>
                <Modal.Title>Izbriši potrebu</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Jeste li sigurni da želite izbrisati potrebu: <b>{needName}</b>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Zatvori
                </Button>
                <Button variant="danger" onClick={mutation.mutateAsync}>
                    Izbriši
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

RemoveNeedModal.propTypes = {
    show: PropTypes.bool,
    handleClose: PropTypes.func,
    needId: PropTypes.number,
    needName: PropTypes.string,
    needType: PropTypes.oneOf(["monetary", "product", "volunteering"]),
};

RemoveNeedModal.defaultProps = {
    show: true,
    handleClose: () => {
        console.log("CLOSING MODAL");
    },
    needId: 123,
    needName: "Test campaing",
    needType: "monetary",
};
