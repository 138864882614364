import "@donatqr/common-iri/src/fixes.css";

import React from "react";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";

import App from "./routes";

if (window.__RUNTIME_CONFIG__.SENTRY_DSN) {
    Sentry.init({ dsn: window.__RUNTIME_CONFIG__.SENTRY_DSN });
}

createRoot(document.getElementById("root")).render(<App />);
