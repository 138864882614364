import React from "react";
import PropTypes from "prop-types";
import { Modal, Button, ListGroup, Col, Row, Form } from "react-bootstrap";
import { XLg, Download } from "react-bootstrap-icons";
import { Utils, Consts } from "@donatqr/common-iri";

import useDonations from "../hooks/useDonations";

function DisplayDonations({ donations, reduceDonation }) {
    const donationListItems = donations.map((donation) => {
        const { data: need } = donation.attributes.need;

        let amount = need.attributes.minimal_donation;
        if (amount === 1) {
            amount *= donation.attributes.quantity;
        }

        return (
            <ListGroup.Item key={donation.id}>
                <Row>
                    <Col className="pt-1" xs={6}>
                        {need.attributes.base.name}
                    </Col>
                    <Col className="pt-1" xs={4}>
                        <span>{amount} €</span>
                        <span className="text-muted"> / {Utils.eurToHrk(amount)} kn</span>
                    </Col>
                    <Col xs={2}>
                        <Button size="sm" variant="outline-danger" onClick={() => reduceDonation(donation.id)}>
                            <XLg />
                        </Button>
                    </Col>
                </Row>
            </ListGroup.Item>
        );
    });

    return <ListGroup variant="flush">{donationListItems}</ListGroup>;
}

DisplayDonations.propTypes = {
    donations: PropTypes.array.isRequired,
    reduceDonation: PropTypes.func.isRequired,
};

function MonetaryDonationDetails({ show, handleClose, donationCollectionId }) {
    const {
        donationCollection,
        donations,
        reduceDonation,
        mutation,
        changeDonationCollectionStatus,
        donationCollectionStatus,
    } = useDonations({
        donationCollectionId,
        enabled: show,
        donationType: "monetary",
    });

    const collectionData = donationCollection?.attributes || {};
    const companyData = collectionData?.company_info || {};
    const donationProof = collectionData?.donation_proof?.data;

    const donationsTotal = Utils.needs.calculateMonetaryDonationsTotal(donations || []);

    return (
        <Modal show={show} onHide={handleClose} animation={false}>
            <Modal.Header closeButton>
                <Modal.Title>Detalji</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Label>Status</Form.Label>
                <select
                    value={donationCollectionStatus}
                    onChange={changeDonationCollectionStatus}
                    className="custom-select mt-2"
                >
                    {Object.entries(Consts.Donations.MONETARY_DONATION_LABEL_BY_STATUS).map(([key, status]) => (
                        <option key={key} value={key}>
                            {status}
                        </option>
                    ))}
                </select>
                <hr />
                {/* DONATOR INFORMATIONS */}
                <span className="text-primary">Informacije o donatoru</span>
                <div className="mt-2">
                    <span className="text-secondary">Ime i prezime</span>
                    <span className="font-weight-bold float-right">{collectionData?.full_name}</span>
                </div>
                <div className="mt-1">
                    <span className="text-secondary">Broj mobitela</span>
                    <span className="font-weight-bold float-right">{collectionData?.phone_number}</span>
                </div>
                <div className="mt-1">
                    <span className="text-secondary">E-mail</span>
                    <span className="font-weight-bold float-right">{collectionData?.email}</span>
                </div>
                <hr />
                {/* COMPANY INFORMATIONS */}
                <span className="text-primary">Informacije o tvrtki</span>
                <div className="mt-2">
                    <span className="text-secondary">Ime</span>
                    <span className="font-weight-bold float-right">{companyData?.name}</span>
                </div>
                <div className="mt-1">
                    <span className="text-secondary">OIB</span>
                    <span className="font-weight-bold float-right">{companyData?.oib}</span>
                </div>
                <div className="mt-1">
                    <span className="text-secondary">Adresa</span>
                    <span className="font-weight-bold float-right">{companyData?.address}</span>
                </div>
                <div className="mt-1">
                    <span className="text-secondary">Odgovorna osoba</span>
                    <span className="font-weight-bold float-right">{companyData?.responsible_person}</span>
                </div>
                <hr />
                {/* DONATION DETAILS */}
                <span className="text-primary">Informacije o donaciji</span>
                <div className="mt-2">
                    <span className="text-secondary">Vrijeme donacije</span>
                    <span className="font-weight-bold float-right">
                        {Utils.dateTimeToString(new Date(collectionData?.createdAt))}
                    </span>
                </div>
                <hr />
                <span className="text-primary">Donacije</span>
                <DisplayDonations donations={donations || []} reduceDonation={reduceDonation} />
                <hr />
                <div className="ml-3 font-weight-bold">
                    Ukupno:
                    <span>{donationsTotal} €</span>
                    <span className="text-muted"> / {Utils.eurToHrk(donationsTotal)} kn</span>
                </div>
                {donationProof && (
                    <a href={donationProof.attributes.url} target="blank">
                        <Download />
                        <span className="ml-2">Preuzmi potvrdu o uplati</span>
                    </a>
                )}
                <hr />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Odustani
                </Button>
                <Button
                    onClick={async () => {
                        await mutation.mutateAsync();
                        handleClose();
                    }}
                    disabled={mutation.isLoading}
                >
                    Spremi
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

MonetaryDonationDetails.propTypes = {
    show: PropTypes.bool,
    handleClose: PropTypes.func,
    donationCollectionId: PropTypes.number,
};

MonetaryDonationDetails.defaultProps = {
    show: false,
    handleClose: () => console.log("CLOSING"),
    donationCollectionId: null,
};

export default MonetaryDonationDetails;
