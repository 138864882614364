export function calculateMonetaryNeedCollectedAmount(need) {
    const donations = need.attributes.donations.data || [];
    const minimalDonation = need.attributes.minimal_donation;

    return donations.reduce((sum, donation) => (
        sum + (donation.attributes.quantity * minimalDonation)
    ), 0);
}


export function calculateNeedCollectedAmount(need) {
    const donations = need.attributes.donations.data || [];

    return donations.reduce((sum, donation) => {
        let quantity = donation.attributes.quantity;
        if (quantity === undefined) quantity = 1;
        return sum + quantity
    }, 0);
}


export function calculateMonetaryDonationsTotal(donations) {
    return donations.reduce((sum, donation) => {
        const { data: need } = donation.attributes.need;
        let quantity = donation.attributes.quantity;

        if (quantity === undefined) {
            quantity = 1;
        }
        let amount = need.attributes.minimal_donation;
        if (amount === 1) {
            amount *= quantity;
        }

        return sum + amount;
    }, 0);
}


export function filterDonationCollections(collections, { name, status }) {
    return collections
        .filter((coll) => !name || coll.attributes.full_name.toLowerCase().includes(name.toLowerCase()))
        .filter((coll) => !status || coll.attributes.status === status);
}


export function getMonetaryDonorType(donationCollection) {
    if (donationCollection?.attributes?.company_info?.id) {
        return "company";
    }
    return "person";
}
