import * as yup from "yup";

export const schema = yup.object({
    name: yup.string().required("Potreban je naziv donacije"),
    description: yup.string().notRequired().max(500, "Opis mora biti duljine najviše 500 znakova"),
    goal: yup
        .number()
        .integer("Novčani cilj mora biti cijeli broj")
        .typeError("Novčani cilj mora biti cijeli broj")
        .positive("Novčani cilj mora biti pozitivan broj")
        .required("Potreban je broj novčani cilj"),
    donationSize: yup
        .number()
        .integer("Veličina donacije mora biti cijeli broj")
        .typeError("Veličina donacije mora biti pozitivan cijeli broj")
        .positive("Veličina donacije mora biti pozitivan broj")
        .transform((_, val) => Number(val) || null)
        .nullable(true),
});
