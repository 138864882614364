import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "react-bootstrap";
import { GeoAltFill } from "react-bootstrap-icons";

import { ImageGallery, InfoWithIcon } from "../components";

function Organization({ name, address, description, imageUrls, children }) {
    return (
        <>
            <Container className="bg-white" fluid>
                <Row className="justify-content-center">
                    <Col lg={8} md={10} xs={11}>
                        <h1 className="pt-5 font-weight-bold">{name}</h1>
                        <p className="text-secondary lead mb-4">
                            <InfoWithIcon icon={<GeoAltFill />} heading="Adresa sjedišta" value={address} />
                        </p>
                        <pre className="lead">{description}</pre>
                    </Col>
                </Row>
            </Container>
            <ImageGallery height="400px" imageUrls={imageUrls} altTemplate={name} />
            <Container className="mt-5 mb-4" fluid>
                {children}
            </Container>
        </>
    );
}

Organization.propTypes = {
    name: PropTypes.string,
    address: PropTypes.string,
    description: PropTypes.string,
    imageUrls: PropTypes.arrayOf(PropTypes.string),
    children: PropTypes.node.isRequired,
};

Organization.defaultProps = {
    name: "Učitavam organizaciju...",
    address: "Učitavam adresu...",
    description: "...",
    imageUrls: ["/loading.gif"],
};

export default Organization;
